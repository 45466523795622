import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import ChatComponent from './ChatComponent';
//import ChatHistoryComponent from './ChatHistoryComponent'; // Adjust the path according to your file structure
import AssistantsGallery from './AssistantsGallery'; // Adjust the relative path as necessary

import PayPalPayment from './PayPalPayment'; 



import './Dashboard.css';
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill'; 
import ImageResize from 'quill-image-resize';
import GlobalSettings from './GlobalSettings';
import { jwtDecode } from 'jwt-decode';


Quill.register('modules/imageResize', ImageResize);


function Dashboard() {
  const [firstName, setFirstName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [assistants, setAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  //const [chatComponentKey, setChatComponentKey] = useState(0);

  const [setSelectedAssistantId] = useState(null); // New state to store selected assistant's ID
  const [copied, setCopied] = useState(false);
  //const [showMessage, setShowMessage] = useState(false);
  //const [openAIAssistantDetails, setOpenAIAssistantDetails] = useState(null); 
  const [copiedEmbedCode, setCopiedEmbedCode] = useState(false);
  //const [instructions, setInstructions] = useState(''); 
  const [editableInstructions, setEditableInstructions] = useState(''); // State for editable instructions
  const [editableName, setEditableName] = useState(''); // New state for editable name
  const [editableModel, setEditableModel] = useState(''); // New state for editable model
  const [editableInitialPrompt, setEditableInitialPrompt] = useState('');
  const [editableRestrictedDomain, setEditableRestrictedDomain] = useState('');
  const [editableChatInstructions, setEditableChatInstructions] = useState('');
  const [editablePicture, setEditablePicture] = useState('');
  const [editableStarter1, setEditableStarter1] = useState('');
  const [editableStarter2, setEditableStarter2] = useState('');
  const [editableStarter3, setEditableStarter3] = useState('');
  const [editableStarter4, setEditableStarter4] = useState('');
  const [editableStarter5, setEditableStarter5] = useState('');
  const [refreshChat] = useState(false);
  const [showGallery, setShowGallery] = useState(false);


  //const { openaiLowerModel, openaiHigherModel } = GlobalSettings.openaiModels;
  //const { displayLowerModel, displayHigherModel } = GlobalSettings.displayModels;

  const [editableIsEnabled, setEditableIsEnabled] = useState(true); // Defaulting to true
const [editableMaxCreditsPerMonth, setEditableMaxCreditsPerMonth] = useState(0); // Defaulting to 0
const [editableIncludeStudentNameInput, setEditableIncludeStudentNameInput] = useState(false);
const [isRichText, setIsRichText] = useState(true);
const richTextEditorRef = React.useRef(null);


 // States for showing explanations
//const [showNameExplanation, setShowNameExplanation] = useState(false);
const [showModelExplanation, setShowModelExplanation] = useState(false);
const [showPersonalityExplanation, setShowPersonalityExplanation] = useState(false);
const [showInitialPromptExplanation, setShowInitialPromptExplanation] = useState(false);
const [showRestrictedDomainExplanation, setShowRestrictedDomainExplanation] = useState(false);
//const [showPictureExplanation, setShowPictureExplanation] = useState(false);
//const [showStarterExplanation, setShowStarterExplanation] = useState(false);
const [showChatInstructionsExplanation, setShowChatInstructionsExplanation] = useState(false);
const [showStartersExplanation, setShowStartersExplanation] = useState(false);
const [showImageUrlExplanation, setShowImageUrlExplanation] = useState(false);

const [showDescriptionLocalExplanation, setShowDescriptionLocalExplanation] = useState(false);
  //const [descriptionLocal, setDescriptionLocal] = useState('');
  //const [isProcessing, setIsProcessing] = useState(false);
  const [editableDescriptionLocal, setEditableDescriptionLocal] = useState('');
  const [showEnabledExplanation, setShowEnabledExplanation] = useState(false);
  //const [showMaxCreditsExplanation, setShowMaxCreditsExplanation] = useState(false);
  const [showIncludeStudentNameExplanation, setShowIncludeStudentNameExplanation] = useState(false);
  const [editableCategory, setEditableCategory] = useState('');
const [editableSubCategory, setEditableSubCategory] = useState('');

const navigate = useNavigate(); // Initialize useNavigate

// Retrieve the token from local storage
const token = localStorage.getItem('token');
// Decode the token to extract the teacherId
const decodedToken = jwtDecode(token);
const teacherId = decodedToken.teacherId;

// Function to validate the token and redirect to login if invalid
useEffect(() => {
  const validateToken = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      await axios.get(`${backendUrl}/validate-token`, { headers: { Authorization: `Bearer ${token}` } });
      // If the token is valid, proceed with no action.
    } catch (err) {
      // If the token is invalid or there's another error, redirect to login.
      navigate('/login');
    }
  };
  
  validateToken();
}, [navigate]);



  useEffect(() => {
    console.log("showGallery state:", showGallery);
  }, [showGallery]);

  const toggleGallery = () => {
    setShowGallery(!showGallery);
  };


  const onSelectAssistant = async (assistantId) => {
    // Find the assistant from the list based on the ID
    const assistant = assistants.find(a => a.assistant_id === assistantId);
    if (assistant) {
      setSelectedAssistant(assistant);
      await fetchLocalAssistantDetails(assistant.api_assistant_id);
      setShowGallery(false); // Add this line to close the gallery
    }
};




// Function to toggle between rich text and raw HTML
const toggleEditorMode = () => {
  setIsRichText(!isRichText);
};


  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');


        const backendUrl = process.env.REACT_APP_BACKEND_URL;
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await axios.get(`${backendUrl}/teachers/me`, config);
        setFirstName(response.data.first_name || 'User');
        const assistantResponse = await axios.get(`${backendUrl}/assistants`, config);
        setAssistants(assistantResponse.data);
      } catch (err) {
        setError('An error occurred. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // New function to fetch OpenAI Assistant Details
  const fetchOpenAIAssistantDetails = async (assistantId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/openai-assistants/${assistantId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching assistant details from OpenAI:', error);
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(selectedAssistant.share_link);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy link: ', err);
    }
  };

  const copyEmbedCodeToClipboard = async () => {
    try {
        await navigator.clipboard.writeText(selectedAssistant.embed_code); // Copy the embed code to clipboard
        setCopiedEmbedCode(true); // Set the embed code copied status to true
        setTimeout(() => setCopiedEmbedCode(false), 2000); // Reset the status after 2 seconds
    } catch (err) {
        console.error('Failed to copy embed code: ', err);
    }
};



// Fetch assistant details from OpenAI and update the state
const fetchAndSetAssistantDetails = async (assistantId) => {
  const openAIDetails = await fetchOpenAIAssistantDetails(assistantId);
  if (openAIDetails) {
      if (openAIDetails.instructions) {
          setEditableInstructions(openAIDetails.instructions);
      }
      if (openAIDetails.name) {
          setEditableName(openAIDetails.name);
      }
      if (openAIDetails.model) {
          setEditableModel(openAIDetails.model);
      }
  }
};

// Function to fetch assistant details from your local database
useEffect(() => {
  // This function will be called whenever the selectedAssistant changes
  if (selectedAssistant) {
    fetchLocalAssistantDetails(selectedAssistant.api_assistant_id);
  }
}, [selectedAssistant]); // Dependency array includes selectedAssistant

const fetchLocalAssistantDetails = async (apiAssistantId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/assistants/${apiAssistantId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });
    const data = response.data;

    // Update states with the fetched data or fallback to a default value
    setEditableInitialPrompt(data.initial_prompt || '');
    setEditableRestrictedDomain(data.restricted_domain || ''); // Fallback to empty string if restricted_domain is null
    setEditableChatInstructions(data.chat_instructions || '');
    setEditablePicture(data.picture || '');
    setEditableStarter1(data.starter_1 || '');
    setEditableStarter2(data.starter_2 || '');
    setEditableStarter3(data.starter_3 || '');
    setEditableStarter4(data.starter_4 || '');
    setEditableStarter5(data.starter_5 || '');
    setEditableDescriptionLocal(data.description_local || '');
    setEditableIsEnabled(selectedAssistant.Is_Enabled);
    setEditableMaxCreditsPerMonth(selectedAssistant.Max_Credits_Per_Month);
    setEditableIncludeStudentNameInput(data.Include_Student_Name_Input);
    setEditableCategory(data.Category || ''); // Set category
    setEditableSubCategory(data.Sub_Category || ''); // Set sub-category
  } catch (error) {
    console.error('Error fetching assistant details:', error);
  }
};



// Function to fetch chat history for a selected assistant
const fetchChatHistoryForAssistant = async (apiAssistantId) => {
  try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/threads/history/${apiAssistantId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      //const chatHistoryData = response.data;
      // You can set this data in a state or pass it to a component to display
  } catch (error) {
      console.error('Error fetching chat history for assistant:', error);
      // Handle error
  }
};
 
useEffect(() => {
  if (selectedAssistant) {
      fetchAndSetAssistantDetails(selectedAssistant.api_assistant_id);
      fetchLocalAssistantDetails(selectedAssistant.api_assistant_id);
      fetchChatHistoryForAssistant(selectedAssistant.api_assistant_id);
  }
}, [selectedAssistant]);







const handleUpdateAssistant = async () => {
  try {
    const token = localStorage.getItem('token');

    await axios.put(`${process.env.REACT_APP_BACKEND_URL}/openai-assistants/${selectedAssistant.api_assistant_id}`, 
      { 
        name: editableName,
        instructions: editableInstructions,
        model: editableModel,
        Model_Type: editableModel,
        initial_prompt: editableInitialPrompt,
        restricted_domain: editableRestrictedDomain,
        chat_instructions: editableChatInstructions, // Send the content as is
        picture: editablePicture,
        description_local: editableDescriptionLocal,
        starter_1: editableStarter1,
        starter_2: editableStarter2,
        starter_3: editableStarter3,
        starter_4: editableStarter4,
        starter_5: editableStarter5,
        Is_Enabled: editableIsEnabled,
        Max_Credits_Per_Month: parseInt(editableMaxCreditsPerMonth, 10) || 0,
        Include_Student_Name_Input: editableIncludeStudentNameInput,
        Category: editableCategory,
        Sub_Category: editableSubCategory
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );

    alert('Assistant updated successfully!');
    // Reload the Dashboard page
    window.location.reload();
  } catch (error) {
    console.error('Error updating assistant: ', error);
    alert('Failed to update assistant. Please try again.');
  }
};


const handleDelete = async () => {
  // Add a confirmation dialog
  const confirmDelete = window.confirm("Are you sure you want to delete this assistant?");
  if (!confirmDelete) {
      return; // Stop the function if the user cancels
  }

  try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/assistants/${selectedAssistant.assistant_id}`, config);

      // Update state to reflect the deletion
      setSelectedAssistant(null);
      setSelectedAssistantId(null); // Reset the assistant ID on deletion
      const updatedAssistants = assistants.filter(assistant => assistant.assistant_id !== selectedAssistant.assistant_id);
      setAssistants(updatedAssistants);

      alert("Assistant deleted successfully."); // Optionally notify the user
  } catch (err) {
      console.error('Failed to delete assistant: ', err);
      alert("Failed to delete assistant."); // Notify the user about the failure
  }
};





  return (
    <div className="container mt-5 dashboard-container">
        <div className="dashboard-header">
            <h2>Welcome to your Dashboard, {loading ? 'Loading...' : firstName}!</h2>
            <p>This is where all of your custom AI assistants live.</p>
        </div>
        {error && <p className="text-danger">{error}</p>}
        <Link to="/create-persona" className="btn btn-outline-primary">
            <i className="bi bi-plus-square-fill" style={{ marginRight: '8px' }}></i>
            Create New AI Assistant
        </Link>
       {/*} <br/><br/>
        <Link to="/text-to-speech" className="btn btn-outline-success">
        <i className="bi bi-speaker" style={{ marginRight: '8px' }}></i>
          Text-To-Speech
  </Link> */}
        <br/><br/>
{/*
        <div>
            <PayPalPayment amount="10.00" currency="CAD" teacherId={teacherId} />
        </div>

*/}
        
        <hr/>

        

        <div className="mt-3 assistant-selection">
            <h4>Chat</h4>
            <label>Select one of your assistants to interact with:</label>
<br/><br/>
            <>
  <button 
    className="btn btn-outline-secondary mb-2 toggle-assistants-button" 
    onClick={toggleGallery}
  >
    <i className={`bi ${showGallery ? 'bi-arrow-up-square-fill' : 'bi-arrow-down-square-fill'}`} style={{ marginRight: '8px' }}></i>
    {showGallery ? 'Hide Assistants' : 'My Assistants'}
  </button>

  <div className={`assistants-gallery ${showGallery ? 'visible' : ''}`}>
    {showGallery && <AssistantsGallery onSelectAssistant={onSelectAssistant} />}
  </div>
</>

{/* Old Dropdown for model selection 
            <select
                className="form-select persona-dropdown"
                onChange={(e) => {
                    const assistantId = e.target.value;
                    const selected = assistants.find(a => a.assistant_id === parseInt(assistantId));
                    setSelectedAssistant(selected);
                }}
            >
                <option value="">-- Select --</option>
                {assistants.map(assistant => (
                    <option key={assistant.assistant_id} value={assistant.assistant_id}>
                        {assistant.assistant_name}
                    </option>
                ))}
            </select>
*/}

        </div>

        

        {selectedAssistant && (
            <div className="mt-5">
                {/* Use chatComponentKey as the key to trigger refresh */}
                <ChatComponent 
            key={selectedAssistant.assistant_id} 
            assistant={selectedAssistant} 
            refreshSignal={refreshChat}
            trustedUse="trustedUse"
        />
          <br/>
          <hr/>
          <h4><u>Sharing</u></h4>
          <br/>
          <Link to={`/chat-history?assistantId=${selectedAssistant.api_assistant_id}`} className="btn btn-primary">
            View Chat History
          </Link>

          
          <p className="mt-3">

             {/* URL Section */}
            Share this link with your students: 
            <a href={selectedAssistant.share_link} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
              {selectedAssistant.share_link}
            </a>
            <button onClick={copyToClipboard} className="btn btn-outline-secondary">
              <i className="bi bi-clipboard"></i>
            </button>
          </p>
          {copied && <div className="alert alert-success" role="alert">Copied to Clipboard</div>}
         {/* Embed Code Section */}
         <div>
                        <p className="mt-3">
                            Copy the embed code to add to your site
                            <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                                {selectedAssistant.embed_code}
                            </span>
                            <button onClick={copyEmbedCodeToClipboard} className="btn btn-outline-secondary">
                                <i className="bi bi-clipboard"></i>
                            </button>
                        </p>
                        {copiedEmbedCode && <div className="alert alert-success" role="alert">Embed Code Copied to Clipboard</div>}
                    </div>

                    {/* Restricted Domain Section */}
<div>
  <label><h5>Restricted Domain:</h5></label>
  <button 
      type="button" 
      className="btn btn-sm ml-2" 
      style={{ background: 'transparent' }}
      onClick={() => setShowRestrictedDomainExplanation(!showRestrictedDomainExplanation)}
  >
      <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
  </button>
  {showRestrictedDomainExplanation && (
    <div className="description-info-text">
      <p>Specify the domain where the AI assistant will be accessible. For instance, if you want the assistant to be used only within your educational institution's domain, enter that domain here.</p>
      
      <p><strong>Example:</strong> "www.myschool.edu"</p>
      
      <p>Entering a domain here restricts the assistant's functionality, ensuring it only operates within the specified domain. This is useful for controlling access and usage, particularly for educational or organizational purposes.</p>
      
      <p><strong>Note:</strong> Make sure to enter the correct domain to avoid issues with the assistant's accessibility.</p>
    </div>
  )}
  <input
      type="text"
      className="form-control"
      placeholder="Enter the restricted domain (e.g., www.myschool.edu)"
      value={editableRestrictedDomain}
      onChange={(e) => setEditableRestrictedDomain(e.target.value)}
  />
</div>


<br/>
<br/>
<hr/>
<h4><u>Information</u></h4>
          <br/>

 {/* Name of assistant */}
                    <div>
    <label><h5>Assistant's Name:</h5></label>
   {/* <button 
        type="button" 
        className="btn btn-sm ml-2" 
        style={{ background: 'transparent' }}
        onClick={() => setShowNameExplanation(!showNameExplanation)}
    >
        <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
    </button>
    {showNameExplanation && (
        <div className="description-info-text">
            hey
        </div>
    )} */}

    <input
        type="text"
        className="form-control"
        value={editableName}
        onChange={(e) => setEditableName(e.target.value)}
    />
</div>
<br/>

{/* Category and Sub_Category */}
<div className="form-group mb-4 d-flex justify-content-between">
  {/* Category */}
  <div className="flex-fill mr-2">
    <label><h5>Category:</h5></label>
    <input
      type="text"
      className="form-control"
      placeholder="Enter category"
      value={editableCategory}
      onChange={(e) => setEditableCategory(e.target.value)}
    />
  </div>

  {/* Sub-Category */}
  <div className="flex-fill ml-2">
    <label><h5>Sub-Category:</h5></label>
    <input
      type="text"
      className="form-control"
      placeholder="Enter sub-category"
      value={editableSubCategory}
      onChange={(e) => setEditableSubCategory(e.target.value)}
    />
  </div>
</div>



<br/>


{/* Description of Assistant */}
<div className="form-group mb-4">
  <label><h5>Description of Assistant</h5></label>
  <button 
    type="button" 
    className="btn btn-sm ml-2" 
    style={{ background: 'transparent' }}
    onClick={() => setShowDescriptionLocalExplanation(!showDescriptionLocalExplanation)}
  >
    <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
  </button>
  {showDescriptionLocalExplanation && (
  <div className="description-info-text">
    <p>This description serves a dual purpose:</p>
    <ul>
      <li>
        <strong>Personal Reminder:</strong> It acts as a reminder for you, the creator, about the specific functions, characteristics, and intended use of your AI assistant. This is particularly helpful if you manage multiple assistants or need to recall the distinct features of this assistant at a later time.
      </li>
      <li>
        <strong>Information for Other Teachers (if Public):</strong> If your AI assistant is shared publicly or with other teachers, this description provides a concise overview of what the assistant does. It informs other educators about the assistant's capabilities, usage scenarios, and any unique attributes that make it useful for educational purposes.
      </li>
    </ul>
    <p>In crafting your description, consider including:</p>
    <ul>
      <li>The main objectives and teaching goals of the assistant.</li>
      <li>Specific subjects, topics, or areas of expertise the assistant covers.</li>
      <li>Unique interaction styles or pedagogical approaches used by the assistant.</li>
      <li>Any prerequisites or intended audience (e.g., grade levels, knowledge level).</li>
      <li>Brief examples or use cases to illustrate how the assistant can be utilized in an educational setting.</li>
    </ul>
    <p>Remember, a clear and detailed description will enhance the usability of your assistant for both yourself and other educators, fostering a more collaborative and informed teaching environment.</p>
  </div>
)}
  <textarea
    className="form-control placeholder-style"
    placeholder="Describe how your AI assistant works..."
    value={editableDescriptionLocal} // Use the state variable
    onChange={(e) => setEditableDescriptionLocal(e.target.value)}
    rows={5}
  />
</div>

<br/>


 {/* Assistant's Welcome & Resources section */}
<div>
    <label><h5>Assistant's Welcome & Resources:</h5></label>

{/* Toggle between Rich Text and Raw HTML */}
<button onClick={toggleEditorMode} className="btn btn-outline-primary btn-sm ml-2">
        {isRichText ? "Switch to HTML" : "Switch to Rich Text"}
    </button>

    <button 
        type="button" 
        className="btn btn-sm ml-2" 
        style={{ background: 'transparent' }}
        onClick={() => setShowChatInstructionsExplanation(!showChatInstructionsExplanation)}
    >
        <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
    </button>
    {showChatInstructionsExplanation && (
  <div className="description-info-text">
    <p>This section allows you to craft an initial message that students will see when they start interacting with the assistant. Although it appears as part of the conversation, the AI does not recognize or "know" this content as part of its conversational context. If you want the AI to be aware of certain information or context, integrate it into the 'Assistant's Personality & Background' and/or 'Instructions to Assistant' sections.</p>
    <p>Consider the following when crafting this message:</p>
    <ul>
      <li><strong>Set the Tone:</strong> Your message should welcome students and set a friendly and inviting tone for the interaction.</li>
      <li><strong>Include Resources:</strong> Use the rich text editor to add educational content such as explanations, tips, or external links to resources. This can be articles, videos, or other learning aids relevant to the subject matter.</li>
      <li><strong>Visibility:</strong> Remember that students can expand the chat to view the content more clearly, so feel free to include detailed information or resources.</li>
      <li><strong>Encourage Interaction:</strong> Your message can include prompts or questions to encourage students to start engaging with the assistant.</li>
    </ul>
    <p>This initial message is a great opportunity to provide context, resources, and an engaging start to the AI-assisted learning experience.</p>
  </div>
)}

     {/* Conditionally render Rich Text Editor or Raw HTML Editor */}
     {isRichText ? (
        <ReactQuill
        ref={richTextEditorRef}
        theme="snow"
        value={editableChatInstructions}
        onChange={setEditableChatInstructions}
        placeholder="Enter chat instructions here..."
        modules={{
            imageResize: {},
            toolbar: [
                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                [{ 'color': [] }, { 'background': [] }],          // color and background
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript  
                ['clean'],                                        // remove formatting
                ['link', 'image', 'video'] ,                      // link, image, and video
                ['code-block']                       
            ],
        }
    }
    />
    ) : (
        <textarea
            value={editableChatInstructions}
            onChange={(e) => setEditableChatInstructions(e.target.value)}
            className="form-control"
            style={{ height: '200px' }} // Set a fixed height for the textarea
        />
    )}
</div>

<br/>

{/* Model */}
<div>
  <label><h5>Assistant's Model:</h5></label>
  <button 
    type="button" 
    className="btn btn-sm ml-2" 
    style={{ background: 'transparent' }}
    onClick={() => setShowModelExplanation(!showModelExplanation)}
  >
    <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
  </button>
  {showModelExplanation && (
    <div className="description-info-text">
      <p>Select the AI model for your assistant. Each model has different capabilities:</p>
      <ul>
        <li><strong>{GlobalSettings.displayModels.displayLowerModel}:</strong> Efficient model suitable for most general purposes.</li>
        <li><strong>{GlobalSettings.displayModels.displayHigherModel}:</strong> Advanced model offering the latest features and capabilities.</li>
      </ul>
      <p>Choose the model that best fits your assistant's needs and the complexity of tasks it will handle.</p>
    </div>
  )}
  <select
    className="form-control"
    value={editableModel === GlobalSettings.openaiModels.openaiLowerModel ? GlobalSettings.displayModels.displayLowerModel 
           : editableModel === GlobalSettings.openaiModels.openaiHigherModel ? GlobalSettings.displayModels.displayHigherModel 
           : editableModel}
    onChange={(e) => setEditableModel(e.target.value === GlobalSettings.displayModels.displayLowerModel ? GlobalSettings.openaiModels.openaiLowerModel 
                                          : e.target.value === GlobalSettings.displayModels.displayHigherModel ? GlobalSettings.openaiModels.openaiHigherModel 
                                          : e.target.value)}
  >
    <option value={GlobalSettings.displayModels.displayLowerModel}>{GlobalSettings.displayModels.displayLowerModel}</option>
    <option value={GlobalSettings.displayModels.displayHigherModel}>{GlobalSettings.displayModels.displayHigherModel}</option>
  </select>
</div>

<br/>

 {/* Assistant's Personality & Background Section */}
<div>
    <label><h5>Assistant's Personality & Background:</h5></label>
    <button 
        type="button" 
        className="btn btn-sm ml-2" 
        style={{ background: 'transparent' }}
        onClick={() => setShowPersonalityExplanation(!showPersonalityExplanation)}
    >
        <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
    </button>
    {showPersonalityExplanation && (
  <div className="description-info-text">
    <p>This is where you define who your AI assistant is, including its personality traits and background. This information shapes how the assistant interacts with students, its style of communication, and its approach to teaching and assisting.</p>
    <p>Consider including:</p>
    <ul>
      <li><strong>Personality Traits:</strong> Describe the assistant's character - whether it's friendly, serious, humorous, or any other personality that aligns with your educational goals.</li>
      <li><strong>Background Information:</strong> Add details about the assistant's expertise, like a particular subject area or teaching style. This helps in creating a more engaging and realistic interaction with students.</li>
      <li><strong>Question Examples and Solutions:</strong> Incorporating examples of questions and their solutions can enrich the assistant's reference material, allowing it to provide more accurate and helpful responses.</li>
    </ul>
    <p>Remember:</p>
    <ul>
      <li><strong>Reference Material:</strong> The content here serves as a reference for the assistant. It can draw upon this information to assist students more effectively.</li>
      <li><strong>Non-Billable Text:</strong> Adding text in this section does not consume your assistant's credits. It's a cost-effective way to enhance your assistant's capabilities.</li>
    </ul>
    <p>By carefully crafting this section, you can significantly influence the effectiveness and personality of your AI assistant, making it a more valuable educational tool.</p>
  </div>
)}
    <textarea
        className="form-control"
        value={editableInstructions}
        onChange={(e) => setEditableInstructions(e.target.value)}
        rows={6}
    />
</div>

<br/>          

               {/* Instructions to Assistant Section */}
               <div>
    <label><h5>Instructions to Assistant:</h5></label>
    <button 
        type="button" 
        className="btn btn-sm ml-2" 
        style={{ background: 'transparent' }}
        onClick={() => setShowInitialPromptExplanation(!showInitialPromptExplanation)}
    >
        <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
    </button>
    {showInitialPromptExplanation && (
  <div className="description-info-text">
    <p>Use this section to provide specific directions to the assistant for its interactions with your students.</p>
    
    <p><strong>A recommended way to start your instructions:</strong></p>
    <blockquote>
      "As a teaching assistant, you will now engage with my students to help them understand [subject/topic]."
    </blockquote>

    <p>This statement informs the AI of its role and purpose but will not be displayed in the student-facing chat interface.</p>

    <p><strong>Key aspects to include in your instructions:</strong></p>
    <ul>
      <li><strong>Role Definition:</strong> Clearly articulate the assistant's role in relation to the subject or activity.</li>
      <li><strong>Objectives for Interaction:</strong> State the primary goals for student interactions, like problem-solving, explaining concepts, or encouraging exploration.</li>
      <li><strong>Guidelines for Behavior:</strong> Specify the expected style and tone of the assistant's responses.</li>
    </ul>
    
    <p><strong>Credit Usage:</strong></p>
    <ul>
      <li>The instructions are understood by the AI and influence its responses but remain invisible in the chat to students.</li>
      <li>Given that this content is processed in each student's interaction, it contributes to credit usage, hence brevity is advised.</li>
      <li>Detailed examples and extensive background information can be more credit-efficiently included in the 'Assistant's Personality & Background' section.</li>
    </ul>
    
    <p>Strategically structuring this section helps you direct the assistant effectively while managing credit use.</p>
  </div>
)}
    <textarea
        className="form-control"
        value={editableInitialPrompt}
        onChange={(e) => setEditableInitialPrompt(e.target.value)}
        rows={6}
    />
</div>


<br/>

              

             {/* Conversation Starters Update Sections */}
<div>
    <label><h5>Conversation Starters:</h5></label>
    <button 
        type="button" 
        className="btn btn-sm ml-2" 
        style={{ background: 'transparent' }}
        onClick={() => setShowStartersExplanation(!showStartersExplanation)}
    >
        <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
    </button>
    {showStartersExplanation && (
  <div className="description-info-text">
    <p>This section is for creating quick-access prompts that students can use to initiate specific topics or questions in their chat with the assistant. These starters are designed to guide student interactions, making it easier for them to engage with the AI on relevant topics.</p>

    <p>Consider these tips for creating effective conversation starters:</p>
    <ul>
      <li><strong>Common Questions:</strong> Include prompts for frequently asked questions or common topics in your subject area.</li>
      <li><strong>Guided Learning:</strong> Use starters to steer students towards particular learning objectives or key concepts.</li>
      <li><strong>Variety:</strong> Offer a range of starters covering different aspects of the subject to cater to diverse student needs and interests.</li>
    </ul>
    
    <p><strong>Pro Tip:</strong> Leverage conversation starters for tailored AI responses. By setting a starter to a specific question or phrase, and then referencing it in the 'Assistant's Personality & Background' or 'Instructions to Assistant,' you can program the AI to take a particular action or provide a specific type of response when a student selects that starter. This allows for more controlled and predictable interactions based on the selected prompt.</p>
    
    <p>Well-crafted conversation starters not only make the chat more interactive but also enhance the educational value of the AI assistant by focusing discussions on key learning areas.</p>
    <p>Note: For math to render properly in this section it needs to be writted in LaTeX form and enclosed in backticks. You can click the 'Format Math' button to have AI convert your math into the correct format</p>
  </div>
)}

    <div className="mb-2">
        <input
            type="text"
            className="form-control"
            placeholder="Conversation Starter 1"
            value={editableStarter1}
            onChange={(e) => setEditableStarter1(e.target.value)}
        />
    </div>
    <div className="mb-2">
        <input
            type="text"
            className="form-control"
            placeholder="Conversation Starter 2"
            value={editableStarter2}
            onChange={(e) => setEditableStarter2(e.target.value)}
        />
    </div>
    <div className="mb-2">
        <input
            type="text"
            className="form-control"
            placeholder="Conversation Starter 3"
            value={editableStarter3}
            onChange={(e) => setEditableStarter3(e.target.value)}
        />
    </div>
    <div className="mb-2">
        <input
            type="text"
            className="form-control"
            placeholder="Conversation Starter 4"
            value={editableStarter4}
            onChange={(e) => setEditableStarter4(e.target.value)}
        />
    </div>
    <div className="mb-2">
        <input
            type="text"
            className="form-control"
            placeholder="Conversation Starter 5"
            value={editableStarter5}
            onChange={(e) => setEditableStarter5(e.target.value)}
        />
    </div>
</div>
<br/>
               
              {/* Picture URL Update Section */}
<div>
  <label><h5>Picture URL:</h5></label>
  <button 
    type="button" 
    className="btn btn-sm ml-2" 
    style={{ background: 'transparent' }}
    onClick={() => setShowImageUrlExplanation(!showImageUrlExplanation)}
  >
    <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
  </button>
  {showImageUrlExplanation && (
  <div className="description-info-text">
    <p>This section allows you to add a visual representation for your AI assistant. The image you choose will appear at the top of the chat interface, providing a personalized touch and making the chat experience more engaging for students.</p>
    
    <p>To add an image:</p>
    <ul>
      <li><strong>Find an Image:</strong> Choose an image that best represents the character or theme of your AI assistant. This could be a relevant photograph, illustration, or any visual that complements the assistant's personality.</li>
      <li><strong>Get the Image URL:</strong> Right-click on the image and select 'Copy image link.' If the link doesn't work directly, try clicking on the image to view it in full size before copying the link.</li>
      <li><strong>Test the Link:</strong> Ensure the image URL works by pasting it into your browser's address bar and checking if the image loads correctly.</li>
      <li><strong>Respect Copyright:</strong> Use only images that are free for use or for which you have the necessary permissions.</li>
    </ul>

    <p>Adding an image makes the assistant more visually appealing and relatable, contributing to a richer interactive experience for students.</p>
  </div>
)}
  <input
    type="text"
    className="form-control"
    value={editablePicture}
    onChange={(e) => setEditablePicture(e.target.value)}
  />
</div>

<br/>



{/* Include Student Name Input Section */}
<div className="text-center mt-4">
  <div>
    <h5 className="d-inline">Include Student Name Input</h5>
    <button 
      type="button" 
      className="btn btn-sm" 
      style={{ background: 'transparent' }}
      onClick={() => setShowIncludeStudentNameExplanation(!showIncludeStudentNameExplanation)}
    >
      <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
    </button>
  </div>
  <div className="form-check form-switch d-flex justify-content-center mt-2">
    <input
      type="checkbox"
      className="form-check-input"
      id="studentNameInputSwitch"
      checked={editableIncludeStudentNameInput} // State variable to control this toggle
      onChange={(e) => setEditableIncludeStudentNameInput(e.target.checked)} // Handler to update the state
      role="switch"
    />
  </div>
  {showIncludeStudentNameExplanation && (
    <div className="description-info-text mt-3">
      <p>Toggle this switch to require students to enter their name before interacting with the AI assistant. Students must enter a name inorder to chat. This will help to monitor or assess conversations</p>
    </div>
  )}
</div>

<br/>



{/* Enable/Disable Assistant Section */}
<div className="text-center">
  <div>
    <h5 className="d-inline">Enable/Disable Assistant</h5>
    <button 
      type="button" 
      className="btn btn-sm" 
      style={{ background: 'transparent' }}
      onClick={() => setShowEnabledExplanation(!showEnabledExplanation)}
    >
      <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
    </button>
  </div>
  <div className="form-check form-switch d-flex justify-content-center mt-2">
    <input
      type="checkbox"
      className="form-check-input"
      id="enabledSwitch"
      checked={editableIsEnabled}
      onChange={(e) => setEditableIsEnabled(e.target.checked)}
      role="switch"
    />
  </div>
  {showEnabledExplanation && (
    <div className="description-info-text mt-3">
      <p>Use this toggle to enable or disable your AI assistant...</p>
    </div>
  )}
</div>


<br/>


{/* Max Credits Per Month Section */}

{/*
<div className="custom-number-input">
  <label><h5>Max Credits Per Month:</h5></label>
  <button 
    type="button" 
    className="btn btn-sm ml-2" 
    style={{ background: 'transparent' }}
    onClick={() => setShowMaxCreditsExplanation(!showMaxCreditsExplanation)}
  >
    <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
  </button>
  {showMaxCreditsExplanation && (
    <div className="description-info-text">
      <p>Set a maximum number of credits that can be consumed by this assistant per month. This is a safeguard to prevent unexpected overuse of the assistant, helping you manage resource allocation and potential costs associated with API usage.</p>
    </div>
  )}
  <input
    type="number"
    className="styled-number-input"
    value={editableMaxCreditsPerMonth}
    onChange={(e) => setEditableMaxCreditsPerMonth(e.target.value)}
    style={{ width: '8ch' }} // Setting the width
  />
</div>
  */}

        
          <br/>
          <p>Date Created: {new Date(selectedAssistant.date_created).toLocaleDateString()}</p>
          <br/>
          
           {/* Update Button */}
           <button onClick={handleUpdateAssistant} className="btn btn-outline-primary mt-2">
                    Update Assistant
                </button>
          <br/>
          <br/>
          
          <button onClick={handleDelete} className="btn btn-outline-danger">Delete Assistant</button>
        </div>
      )}
    </div>
  );
}

export default Dashboard;