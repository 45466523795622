import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ChatHistoryComponent.css'; // Shared CSS



function AssistantsGallery({ onSelectAssistant}) {
  const [assistants, setAssistants] = useState([]);
  const [error, setError] = useState(null);
  const [selectedAssistants, setSelectedAssistants] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const groupAssistantsByCategory = (assistants) => {
    const groupedByCategory = assistants.reduce((result, assistant) => {
      const category = assistant.Category || 'Uncategorized'; // Fallback for undefined categories
      if (!result[category]) {
        result[category] = [];
      }
      result[category].push(assistant);
      return result;
    }, {});
  
    // For each category, group assistants by sub-category
    Object.keys(groupedByCategory).forEach(category => {
      const groupedBySubCategory = groupedByCategory[category].reduce((subResult, assistant) => {
        const subCategory = assistant.Sub_Category || 'General'; // Fallback for undefined sub-categories
        if (!subResult[subCategory]) {
          subResult[subCategory] = [];
        }
        subResult[subCategory].push(assistant);
        return subResult;
      }, {});
      groupedByCategory[category] = groupedBySubCategory;
    });
  
    return groupedByCategory;
  };
  
  



  useEffect(() => {
    const fetchAssistants = async () => {
      setIsLoading(true); // Set loading to true before fetching data
      try {
        const token = localStorage.getItem('token');
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/assistants`, config);
        setAssistants(response.data);
        setIsLoading(false); // Set loading to false after fetching data
      } catch (err) {
        setError('An error occurred while fetching assistants.');
        setIsLoading(false); // Ensure loading is set to false even if there is an error
      }
    };
    

    fetchAssistants();
  }, []);




  const handleCheckboxChange = (e, id) => {
    e.stopPropagation(); // Prevents the click event from bubbling up to parent elements
  
    if (e.target.checked) {
      setSelectedAssistants([...selectedAssistants, id]);
    } else {
      setSelectedAssistants(selectedAssistants.filter(assistantId => assistantId !== id));
    }
  };
  
  // Delete all selected assistants
const handleBulkDelete = async () => {
  // Check if any assistants are selected
  if (selectedAssistants.length === 0) {
    alert("Please select an assistant to delete.");
    return;
  }

  const confirmDelete = window.confirm("Are you sure you want to delete the selected assistants?");
  if (!confirmDelete) {
    return;
  }

  try {
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: `Bearer ${token}` } };

    for (const assistantId of selectedAssistants) {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/assistants/${assistantId}`, config);
    }

    // Update state to reflect the deletion
    const updatedAssistants = assistants.filter(assistant => !selectedAssistants.includes(assistant.assistant_id));
    setAssistants(updatedAssistants);
    setSelectedAssistants([]); // Reset the selected assistants

    alert("Selected assistants deleted successfully.");
  } catch (err) {
    console.error('Failed to delete assistants: ', err);
    alert("Failed to delete assistants.");
  }
};


// New function to fetch OpenAI Assistant Details
const fetchOpenAIAssistantDetails = async (assistantId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/openai-assistants/${assistantId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching assistant details from OpenAI:', error);
  }
};


const handleCopyAssistant = async (assistant, event) => {
  event.stopPropagation(); // Prevents the event from bubbling up to parent elements

  try {
    // Fetch OpenAI assistant details
    const openAIDetails = await fetchOpenAIAssistantDetails(assistant.api_assistant_id);
    if (!openAIDetails) {
      alert('Failed to fetch assistant details from OpenAI.');
      return;
    }

    // Now create a copy of the assistant
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/assistants`, {
      assistantName: `${assistant.assistant_name} copy`,
      description: assistant.description,
      model: openAIDetails.model, // Use the model obtained from OpenAI.
      personality: openAIDetails.instructions, // Use instructions from OpenAI as personality.
      chatInstructions: assistant.chat_instructions,
      descriptionLocal: assistant.description_local,
      initialPrompt: assistant.initial_prompt,
      pictureUrl: assistant.picture,
      starter_1: assistant.starter_1,
      starter_2: assistant.starter_2,
      starter_3: assistant.starter_3,
      starter_4: assistant.starter_4,
      starter_5: assistant.starter_5,
      Include_Student_Name_Input: assistant.Include_Student_Name_Input,
      restrictedDomain: assistant.restricted_domain,
      category: assistant.Category,
      subCategory: assistant.Sub_Category
    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      }
    });

    if (response.data) {
      alert('Assistant copied successfully');
      window.location.reload();
    }
  } catch (error) {
    console.error('Error copying assistant:', error);
    alert('Failed to copy assistant. Please try again.');
  }
};

      



return (
  <div className="assistants-gallery-container">
    <br />
    {selectedAssistants.length > 0 && (
      <div className="delete-button-container">
        <button className="btn btn-danger" onClick={handleBulkDelete}>Delete Selected</button>
      </div>
    )}

<div className="assistants-gallery-container">
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        id="showDetailsSwitch"
        checked={showDetails}
        onChange={() => setShowDetails(!showDetails)}
      />
      <label className="form-check-label" htmlFor="showDetailsSwitch">Show Assistant Details</label>
    </div>
    </div>

    <br />

    <div className="gallery1">
      {error && <p className="error-message">{error}</p>}
      {isLoading && <p>Loading assistants...</p>}

      {!isLoading && (
        Object.entries(groupAssistantsByCategory(assistants)).map(([category, subCategories]) => (
          <div key={category} className="category-container">
            <h2 className="category-header">{category}</h2>
            {Object.entries(subCategories).map(([subCategory, groupedAssistants]) => (
              <div key={subCategory} className="sub-category-container">
                <h3 className="sub-category-header">{subCategory}</h3>
    
    {groupedAssistants.map((assistant, index) => (
  <div key={index} 
       className="chat-history-item"
       data-bs-toggle="tooltip" 
       title={assistant.description_local}
  >
    <input
      type="checkbox"
      className="assistant-checkbox"
      checked={selectedAssistants.includes(assistant.assistant_id)}
      onChange={(e) => handleCheckboxChange(e, assistant.assistant_id)}
    />
    <div className="assistant-details" onClick={() => onSelectAssistant(assistant.assistant_id)}>
      <h3 className="assistant-name">{assistant.assistant_name}</h3>
      <button className="btn btn-outline-primary btn-sm copy-assistant-button" onClick={(e) => handleCopyAssistant(assistant, e)}>

Copy</button>

     



    </div>
    {showDetails && (
      <div className="assistant-details-extra">
        <p><label>Model Type:</label> {assistant.Model_Type}</p>
        <p><label>Restricted Domain:</label> {assistant.restricted_domain}</p>
        <p><label>Date Created:</label> {new Date(assistant.date_created).toLocaleDateString()}</p>
        <p><label>Is Enabled:</label> {assistant.Is_Enabled ? 'Yes' : 'No'}</p>
        <p><label>Include Student Name Input:</label> {assistant.Include_Student_Name_Input ? 'Yes' : 'No'}</p>
        <p><label>Credits Lower Model:</label> {assistant.Credits_Lower_Model}</p>
        <p><label>Credits Higher Model:</label> {assistant.Credits_Higher_Model}</p>
        <p><label>Description:</label> {assistant.description_local}</p>
      </div>
    )}
  </div>
))}

              </div>
            ))}
          </div>
        ))
      )}
    </div>
  </div>
);




}

export default AssistantsGallery;
