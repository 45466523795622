import React, { useEffect, useRef } from 'react';

const JSXGraphComponent = ({ jsxCode, boardId, size = { width: '450px', height: '450px' } }) => {
    const boardRef = useRef(null);

    useEffect(() => {
      if (window.JXG && boardRef.current && jsxCode) {
        // Set unique ID for the board container
        boardRef.current.id = boardId;

        let board = window.JXG.JSXGraph.initBoard(boardRef.current.id, {
          boundingbox: [-10, 10, 10, -10],
          axis: true,
          showCopyright: false
        });

        try {
          new Function('board', jsxCode)(board);
        } catch (error) {
          console.error('Error executing JSXGraph code:', error);
        }

        return () => {
          // Cleanup when component unmounts
          if (board) {
            board.removeObject(board.objectsList);
          }
        };
      }
    }, [jsxCode, boardId]); // Add boardId to dependency array

    return (
        <div ref={boardRef} style={{ width: size.width, height: size.height }}></div>
    );
};

export default JSXGraphComponent;
