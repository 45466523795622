import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ChatThreadPopup.css';

function ChatThreadPopup({ threadId, studentName, closePopup }) {
    const [threadMessages, setThreadMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchThreadMessages = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/threads/${threadId}/messages`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setThreadMessages(response.data.data.reverse());
            } catch (err) {
                setError('Failed to fetch thread messages.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchThreadMessages();
    }, [threadId]);

    const renderMessageContent = (content) => {
        return content.map((item, index) => {
            if (item.type === 'text' && item.text) {
                return <span key={index}>{item.text.value}</span>;
            }
            // Handle other types of content as needed
            return null;
        });
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="chat-thread-overlay">
            <div className="chat-thread-popup">
                <button onClick={closePopup} className="close-button">Close</button>
                {studentName && <h2 className="student-name-title">{studentName}</h2>}
                <div className="messages-container">
                    {threadMessages.map((message, index) => (
                        <div key={index} className={`message-item ${message.role}`}>
                            <p>
                                <strong>{message.role}:</strong> {renderMessageContent(message.content)}
                                <br />
                                <span className="timestamp">
                                    {new Date(message.created_at * 1000).toLocaleString()}
                                </span>
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ChatThreadPopup;
