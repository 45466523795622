import React, { useState } from 'react';
import axios from 'axios';
import './AuthFormStyles.css'; // Assuming you have this CSS in the same directory as Register.js

function Register() {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
        first_name: '',
        last_name: ''
    });
    const [message, setMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const isFormIncomplete = () => {
      return !formData.username || !formData.password || !formData.email || !formData.first_name || !formData.last_name;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            const response = await axios.post(`${backendUrl}/register`, formData);

            if (response.data.success) {
                setMessage(response.data.message);
            } else {
                setMessage(response.data.error || response.data.message);
            }
        } catch (error) {
          // Check if the error response contains a specific message
          if (error.response && error.response.data.message) {
              setMessage(error.response.data.message);
          } else {
              setMessage('Error registering.');
          }}
    };

    return (
      <div className="login-dark">
          <form onSubmit={handleSubmit}>
              <h2 className="sr-only">Registration Form</h2>
              <div className="illustration"><i className="icon ion-clipboard"></i></div>
              
              <div className="instructions">
                  <p>Welcome to the registration page. Please fill out the following fields to create your account:</p>
              </div>
              
              <div className="form-group">
                  <input className="form-control" type="text" name="first_name" placeholder="First Name" onChange={handleInputChange} />
              </div>
              <div className="form-group">
                  <input className="form-control" type="text" name="last_name" placeholder="Last Name" onChange={handleInputChange} />
              </div>
              <div className="form-group">
                  <input className="form-control" type="email" name="email" placeholder="Email" onChange={handleInputChange} />
              </div>
              {/* Space between email and password */}
              <div className="form-spacing"></div>
              <div className="form-group">
                  <input className="form-control" type="text" name="username" placeholder="Username" onChange={handleInputChange} />
              </div>
              <div className="form-group">
                  <input className="form-control" type="password" name="password" placeholder="Password" onChange={handleInputChange} />
              </div>
              <div className="form-group">
                  <button className="btn btn-primary btn-block" type="submit" disabled={isFormIncomplete()}>Register</button>
              </div>
              {message && <p className="message-error">{message}</p>}
          </form>
      </div>
    );
}

export default Register;
