import React, { useRef, useEffect } from 'react';
import { EditorState } from '@codemirror/state';
import { EditorView, lineNumbers, highlightActiveLine } from '@codemirror/view';

import { javascript } from '@codemirror/lang-javascript';
import { html } from '@codemirror/lang-html';
import { css } from '@codemirror/lang-css';
import { python } from '@codemirror/lang-python';
import { php } from '@codemirror/lang-php';
import { oneDark } from '@codemirror/theme-one-dark';




const CodeDisplay = ({ code, language }) => {
  const editorDiv = useRef(null);

  useEffect(() => {
    if (editorDiv.current) {
      let langMode;
      switch (language) {
        case 'javascript':
          langMode = javascript();
          break;
        case 'html':
          langMode = html();
          break;
        case 'css':
          langMode = css();
          break;
        case 'python':
          langMode = python();
          break;
        case 'php':
          langMode = php();
          break;
        default:
          langMode = javascript(); // Default to JavaScript if language is unknown
      }
      

      const state = EditorState.create({
        doc: code,
        extensions: [oneDark, langMode, EditorView.editable.of(false), lineNumbers(),highlightActiveLine()],
      });

      const view = new EditorView({
        state,
        parent: editorDiv.current,
      });

      return () => {
        if (view) {
          view.destroy();
        }
      };
    }
  }, [code, language]);

  return (
    <div 
      ref={editorDiv} 
  
    ></div>
  );
  


};

export default CodeDisplay;
