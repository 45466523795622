import React from 'react';

const TestComponent = () => {
  return (
    <div>
      <h2>Test Component</h2>
      <p>This is a test component for domain restriction testing.</p>
      <a href="http://localhost:5000/api/validate-chat/asst_zjbbBJpACOUknySfGHerwh9y/289c2d3a-1a39-4826-ae1c-8e5d3d73fc9a">
  Access the Assistant
</a>

<iframe src="http://localhost:5000/api/validate-chat/asst_U8OQ9hwD3EZGGX98op5eW7uN/4c4b319f-2405-4d15-b52b-223df968ed84" width="600" height="700" frameborder="0"></iframe>

    </div>
  );
};

export default TestComponent;
