import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';

function LandingPage() {
  return (
    <div className="container mt-5">
      <div className="jumbotron">
        <h1 className="display-4">Welcome to AI Assistant</h1>
        <p className="lead">Create and manage your AI Assistants with ease.</p>
        <hr className="my-4"/>
        <p>Start by logging in if you already have an account</p>
        {/*<Link to="/register" className="btn btn-primary m-1">Register</Link>*/}
        <Link to="/login" className="btn btn-light">Login</Link>
      </div>
    </div>
  );
}

export default LandingPage;
