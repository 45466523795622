
import './App.css'; 
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import CreatePersona from './components/CreatePersona';
import ChatComponent from './components/ChatComponent';
import ChatHistoryComponent from './components/ChatHistoryComponent';
import AccessRestricted from './components/AccessRestricted';
import TestComponent from './components/TestComponent';
import AssistantsGallery from './components/AssistantsGallery';
import TextToSpeechComponent from './components/TextToSpeechComponent';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";


//import { useAuth } from './components/AuthContext';

import { AuthProvider } from './components/AuthContext';

function App() {

  const initialOptions = {
    "clientId": "AaI6LXQoD2-DOGqmUzBRAXhmjj_cXHd5zybLIGxtdcuJx_yxa4cD0ujH25lOSw84_F2CvH6g4Zc1BO0U",
    "currency": "CAD",
    "intent": "capture"
    // You can add other options here
  };


  return (

    <PayPalScriptProvider options={initialOptions}>

    <Router>

<AuthProvider>

      <div className="App">
        <Routes>
          <Route path="/" element={<LayoutWithNavbar><LandingPage /></LayoutWithNavbar>} />
          <Route path="/login" element={<LayoutWithNavbar><Login /></LayoutWithNavbar>} />
          <Route path="/register" element={<LayoutWithNavbar><Register /></LayoutWithNavbar>} />
          <Route path="/dashboard" element={<LayoutWithNavbar><Dashboard /></LayoutWithNavbar>} />
          <Route path="/create-persona" element={<LayoutWithNavbar><CreatePersona /></LayoutWithNavbar>} />
          <Route path="/chat/:apiAssistantId/:token" element={<ChatComponent />} />
          <Route path="/chat-history" element={<LayoutWithNavbar><ChatHistoryComponent /></LayoutWithNavbar>} />
          <Route path="/access-restricted" element={<LayoutWithNavbar><AccessRestricted /></LayoutWithNavbar>} />
          <Route path="/test-component" element={<LayoutWithNavbar><TestComponent /></LayoutWithNavbar>} />
          <Route path="/assistants-gallery" element={<LayoutWithNavbar><AssistantsGallery /></LayoutWithNavbar>} />
          <Route path="/text-to-speech" element={<LayoutWithNavbar><TextToSpeechComponent /></LayoutWithNavbar>} />

        </Routes>
      </div>
      
      </AuthProvider>

    </Router>

    </PayPalScriptProvider>
  );
}








// Layout component with Navbar
function LayoutWithNavbar({ children }) {
  return (
    <>
      <Navbar />
      {children}
    </>
  );
}

// Navbar component
function Navbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if token exists in local storage
        const token = localStorage.getItem('token');
        setIsLoggedIn(!!token);
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        navigate('/login');
    };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark custom-navbar">
      <Link to="/" className="navbar-brand">
        <img src={`${process.env.PUBLIC_URL}/ai_logo_chat.png`} alt="Logo" />
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to="/dashboard" className="nav-link">
              <i className="bi bi-speedometer2" style={{ marginRight: '8px' }}></i>
              Dashboard
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/create-persona" className="nav-link">
              <i className="bi bi-person-plus" style={{ marginRight: '8px' }}></i>
              Create Persona
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/text-to-speech" className="nav-link">
              <i className="bi bi-mic" style={{ marginRight: '8px' }}></i>
              Text-to-Speech
            </Link>
          </li>
        </ul>
      </div>
      <div className="navbar-nav ml-auto">
        {isLoggedIn ? (
          <button onClick={handleLogout} className="btn btn-link nav-link" style={{ color: 'rgba(255, 255, 255, 0.55)' }}>
            <i className="bi bi-box-arrow-right" style={{ marginRight: '8px' }}></i>
            Log Out
          </button>
        ) : (
          <Link to="/login" className="nav-link">
            <i className="bi bi-box-arrow-in-right" style={{ marginRight: '8px' }}></i>
            Login
          </Link>
        )}
      </div>
    </nav>
  );
}





export default App;
