import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ChatComponent.css';

// Correct imports for CodeMirror
import { EditorState } from '@codemirror/state'; // Make sure to import EditorState from '@codemirror/state'
import { EditorView, keymap, lineNumbers } from '@codemirror/view'; // Make sure to import EditorView, keymap, and lineNumbers from '@codemirror/view'

import { javascript } from '@codemirror/lang-javascript'; // Make sure to import the language modes you want to use
import { html } from '@codemirror/lang-html'; // Make sure to import the language modes you want to use
import { css } from '@codemirror/lang-css'; // Make sure to import the language modes you want to use
import { python } from '@codemirror/lang-python'; // Make sure to import the language modes you want to use
import { php } from '@codemirror/lang-php'; // Make sure to import the language modes you want to use
import { oneDark } from '@codemirror/theme-one-dark'; // Make sure to import the theme you want to use
import { defaultKeymap } from '@codemirror/commands'; // Make sure to import defaultKeymap from '@codemirror/commands'

import DOMPurify from 'dompurify'; // Import DOMPurify to sanitize HTML content
import CodeDisplay from './CodeDisplay'; // Import the CodeDisplay component
import JSXGraphComponent from './JSXGraphComponent'; // Import the JSXGraphComponent component





// Chat Component
const ChatComponent = ({ assistant: propAssistant, trustedUse  }) => {
    const { apiAssistantId: paramApiAssistantId, token } = useParams(); // Get the API assistant ID and token from the URL
    const [apiAssistantId] = useState(propAssistant ? propAssistant.api_assistant_id : paramApiAssistantId); // Use the API assistant ID from the URL if it exists
    const [assistant, setAssistant] = useState(propAssistant); // Use the assistant from the props if it exists
    const [threadId, setThreadId] = useState(null); // State to store the thread ID
    const [messages, setMessages] = useState([]); // State to store the messages
    const [inputValue, setInputValue] = useState(''); // State to store the chat input
    const [isTyping, setIsTyping] = useState(false); // State to store whether the assistant is typing
    const chatContainerRef = useRef(null); // Ref for the chat container
    const [color] = useState(['#9932CC', '#007bff', '#008000'][Math.floor(Math.random() * 3)]); // State to store the color
    const isInIframe = window.location !== window.parent.location; // Check if the app is in an iframe
    //console.log("Is in iframe:", isInIframe);

    
    const [mathInput, setMathInput] = useState(''); // State to store MathQuill input
    const mathFieldRef = useRef(null); // Ref for the MathQuill input field
    const [showMathInput, setShowMathInput] = useState(false); // State to store whether to show the MathQuill input
    const mathToggleRef = useRef(null); // Ref for the MathQuill toggle button
    const editorDiv = useRef(null); 
    const [editor, setEditor] = useState(null); 
    const [languageMode, setLanguageMode] = useState(html);
    const [showCodeEditor, setShowCodeEditor] = useState(false);  // State to store whether to show the CodeMirror editor
    const [languageLabel, setLanguageLabel] = useState('html'); // Initialize with 'html'
    const [expandedMessages, setExpandedMessages] = useState(new Set());
    const [studentName, setStudentName] = useState('');
    const [isNameEntered, setIsNameEntered] = useState(false);
    const tokenCheckedRef = useRef(false);
    
    const [isAudioLoading, setIsAudioLoading] = useState(false);
    const [audioStatus, setAudioStatus] = useState({});
    const CHARACTER_LIMIT = 300; // Character limit to show audio button at top as well

    


  
    
 
    const toggleExpand = (index) => {
        setExpandedMessages(prevExpanded => {
          const newExpanded = new Set(prevExpanded);
          if (newExpanded.has(index)) {
            newExpanded.delete(index);
          } else {
            newExpanded.add(index);
          }
          return newExpanded;
        });
      };

      function PopOut({ message, onClose }) {
        const segments = typeof message.content === 'string' 
                         ? processMessageContent(message.content) 
                         : message.content;

    
        useEffect(() => {
            // Trigger MathJax to process the new content in the pop-out
            if (window.MathJax) {
                window.MathJax.typesetPromise();
            }
        }, [segments]);
    
        return (
            <div className="pop-out-container">
                
                
                <button className="pop-out-close" onClick={onClose}>
                    <i className="fas fa-times"></i>
                </button>
                
                {/* Scrollable content area */}
                <div className="pop-out-scrollable-content">
                    {segments.map((segment, index) => {
                        switch (segment.type) {
                            case 'code':
                                // Extract the language from the first line of the code block
                                const [firstLine, ...codeLines] = segment.content.substring(3, segment.content.length - 3).split('\n');
                                const language = firstLine.trim();
                                const code = codeLines.join('\n').trim();
                                return <CodeDisplay key={index} code={code} language={language} />;
    
                            case 'jsxGraph':
                                const jsxCode = segment.content.substring(5, segment.content.length - 6).trim();
                                return <JSXGraphComponent key={index} jsxCode={jsxCode} boardId={`popout-jsxgraph-${index}`} />;
    
                            case 'text':
                                const formattedText = formatMessageWithLatex(segment.content);
                                return <span key={index} dangerouslySetInnerHTML={{ __html: formattedText }} />;
    
                            default:
                                return <span key={index}>Unsupported content format</span>;
                        }
                    })}
                </div>
            </div>
        );
    }
    
      
   




const toggleCodeEditor = () => {
    setShowCodeEditor(!showCodeEditor);
    console.log("Toggled showCodeEditor to", !showCodeEditor); // For debugging
};


    

    // Function to toggle the MathQuill input visibility
    const toggleMathInput = () => {
        setShowMathInput(!showMathInput);
        console.log("Toggled showMathInput to", !showMathInput); // Add this line for debugging
    };
    
    // Function to handle the MathQuill submit button click
    const handleMathSubmit = () => {
        // Append MathQuill input wrapped in backticks to the current chat input
        setInputValue(prevInputValue => `${prevInputValue}${prevInputValue ? ' ' : ''}\`${mathInput}\``);
    
        // Clear MathQuill input state
        setMathInput('');
    
        // Directly reset the MathQuill field's content
        if (mathFieldRef.current) {
            const MQ = window.MathQuill.getInterface(2);
            const mathField = MQ.MathField(mathFieldRef.current);
            mathField.latex(''); // Reset the MathQuill input to empty
        }
    };
    
    // Existing useEffect for initializing MathQuill
    useEffect(() => {
        if (window.MathQuill && mathFieldRef.current) {
            const MQ = window.MathQuill.getInterface(2);
            const mathFieldInstance = MQ.MathField(mathFieldRef.current, {
                handlers: {
                    edit: () => {
                        setMathInput(mathFieldInstance.latex());
                    }
                }
            });
    
            const handleEnterKey = (event) => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    handleSend();
                }
            };
    
            mathFieldRef.current.addEventListener('keydown', handleEnterKey);
    
            // Clean up the event listener on component unmount
            return () => {
                // Adding null check here
                if (mathFieldRef.current) {
                    mathFieldRef.current.removeEventListener('keydown', handleEnterKey);
                }
            };
        }
    }, [mathInput]); // Including mathInput in the dependency array
    
    
    
    
// Function to play or resume the audio
const playAudio = async (messageId, messageContent) => {
    try {
        if (!audioStatus[messageId]?.audioUrl) {
            setIsAudioLoading(prevState => ({ ...prevState, [messageId]: true }));

            const assistantId = apiAssistantId;
            const textString = concatenateTextSegments([{ role: 'assistant', content: messageContent }]);

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/convert-and-speak`, { text: textString, assistantId }, {
                responseType: 'blob'
            });

            const newAudioUrl = URL.createObjectURL(response.data);
            const newAudio = new Audio(newAudioUrl);

            newAudio.oncanplaythrough = () => setIsAudioLoading(prevState => ({ ...prevState, [messageId]: false }));
            newAudio.onended = () => setAudioStatus(prevStatus => ({
                ...prevStatus,
                [messageId]: { ...prevStatus[messageId], isPlaying: false, isPaused: false }
            }));

            setAudioStatus(prevStatus => ({
                ...prevStatus,
                [messageId]: { audioUrl: newAudioUrl, audio: newAudio, isPlaying: true, isPaused: false }
            }));

            newAudio.play().catch(e => {
                console.error('Error playing audio:', e);
                setIsAudioLoading(prevState => ({ ...prevState, [messageId]: false }));
            });
        } else {
            const currentAudio = audioStatus[messageId].audio;
            if (audioStatus[messageId].isPaused) {
                currentAudio.play().catch(e => {
                    console.error('Error resuming audio:', e);
                });
                setAudioStatus(prevStatus => ({
                    ...prevStatus,
                    [messageId]: { ...prevStatus[messageId], isPlaying: true, isPaused: false }
                }));
            } else {
                currentAudio.play().catch(e => {
                    console.error('Error playing audio:', e);
                });
                setAudioStatus(prevStatus => ({
                    ...prevStatus,
                    [messageId]: { ...prevStatus[messageId], isPlaying: true }
                }));
            }
        }
    } catch (error) {
        console.error('Error setting up audio:', error);
        setIsAudioLoading(prevState => ({ ...prevState, [messageId]: false }));
    }
};

// Function to pause the audio
const pauseAudio = (messageId) => {
    if (audioStatus[messageId]) {
        audioStatus[messageId].audio.pause();
        setAudioStatus(prevStatus => ({
            ...prevStatus,
            [messageId]: { ...prevStatus[messageId], isPlaying: false, isPaused: true }
        }));
    }
};

// Function to stop the audio
const stopAudio = (messageId) => {
    if (audioStatus[messageId]) {
        audioStatus[messageId].audio.pause();
        audioStatus[messageId].audio.currentTime = 0; // Reset audio to the beginning
        setAudioStatus(prevStatus => ({
            ...prevStatus,
            [messageId]: { ...prevStatus[messageId], isPlaying: false, isPaused: false }
        }));
    }
};



    



      
  

// Function to initialize chat
const initializeChat = async () => {
    try {
        // Get the static token from session storage
        const storedToken = sessionStorage.getItem('staticToken');

        if (!assistant && apiAssistantId && (token || storedToken)) {
            // Fetch assistant data from the backend
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/chat/${apiAssistantId}/${token || storedToken}`);
            setAssistant(response.data);
        }

        // Initialize thread if not already done
        if (!threadId && apiAssistantId) {
            const threadResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/threads`, {
                assistantId: apiAssistantId
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setThreadId(threadResponse.data.id);
        }
    } catch (error) {
        console.error('Error initializing chat:', error);
        // Optionally, handle the error more gracefully here
    }
};

// useEffect for token validation and initializing chat
useEffect(() => {
    if (tokenCheckedRef.current) return; // Only run this logic once

    // Bypass token check if trustedUse is provided
    if (trustedUse) {
        console.log("Trusted use, bypassing token validation");
        tokenCheckedRef.current = true;
        initializeChat(); // Initialize chat directly
        return;
    }

    // Extract the staticToken from the URL
    const urlParams = new URLSearchParams(window.location.search);
    let staticToken = urlParams.get('staticToken');

    // If staticToken is not found in the URL, check the session storage
    if (!staticToken) {
        staticToken = sessionStorage.getItem('staticToken');
    }

    console.log("Extracted staticToken:", staticToken);

    if (staticToken !== 'a34e7cdd19b67d3db632cb2762cb09d1') {
        console.log("Static token does not match, redirecting to access-restricted");
        window.location.href = '/access-restricted';
        return;
    }

    // Token validation successful, update the ref
    tokenCheckedRef.current = true;
    console.log("Token checked and updated");

    // Store the token in session storage
    sessionStorage.setItem('staticToken', staticToken);

    // Remove the token from the URL
    urlParams.delete('staticToken');
    window.history.replaceState(null, null, `${window.location.pathname}?${urlParams}`);
    console.log("Static token removed from URL");

    // Initialize chat
    initializeChat();
}, [assistant, apiAssistantId, token, threadId, paramApiAssistantId]);

// Refresh Chat Function
// Refresh Chat Function
const refreshChat = () => {
    window.location.reload();
};



// Function to fetch and display the image
const fetchAndDisplayImage = async (fileId, token) => {
    try {
        const imageUrl = `${process.env.REACT_APP_BACKEND_URL}/files/content/${fileId}`;
        const response = await axios.get(imageUrl, {
            responseType: 'blob',
            headers: { Authorization: `Bearer ${token}` }
        });
        const url = URL.createObjectURL(response.data);
        const newImageMessage = {
            role: 'assistant',
            content: `<img src="${url}" alt="Image Content" style="max-width: 100%; max-height: 300px;" />`
        };
        setMessages(prevMessages => [...prevMessages, newImageMessage]);
    } catch (error) {
        console.error('Error fetching image:', error);
        setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: '[Error fetching image]' }]);
    }
};





    // Fetch messages from the thread
    useEffect(() => {
        if (chatContainerRef.current && messages.length > 0) {
            // Find the last user message element
            const userMessages = Array.from(chatContainerRef.current.getElementsByClassName('user'));
            const lastUserMessageElement = userMessages[userMessages.length - 1];
    
            if (lastUserMessageElement) {
                // Calculate the offset position of the last user message relative to the chat container
                const offset = lastUserMessageElement.offsetTop - chatContainerRef.current.offsetTop;
    
                // Set the scroll position of the chat container
                chatContainerRef.current.scrollTop = offset;
            }
        }
    }, [messages]);
    
    
    
    
    
    


    // Function to handle the chat input change
    const sendMessage = async (message) => {
        const timeoutDuration = 20000; // 20 seconds for timeout
        let timeoutId;
    
        // Function to handle timeout scenario
        const handleTimeout = () => {
            alert('There was an issue receiving a response. This could be due to an issue at OpenAI or a poor internet connection. Please refresh the chat.');
        };
    
        // Start a timeout to handle long response times
        timeoutId = setTimeout(handleTimeout, timeoutDuration);
    
        try {
            // Extend the message to include assistant's ID and student name
            const extendedMessage = {
                ...message,
                assistantId: assistant.api_assistant_id,
                studentName
            };
            console.log('Sending Message:', extendedMessage);
    
            // Send the extended message to the backend
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/threads/${threadId}/messages`, extendedMessage, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
    
            if (response.status === 403) {
                // Handle the specific case where the profile does not have enough credits
                alert("This profile does not have enough credits to continue. If you are the owner of this edbot, you can purchase more credits at edbotz.com.");
                return; // Exit the function early as no message will be processed due to lack of credits
            }
    
            // Message sent successfully, clear the timeout
            clearTimeout(timeoutId);
    
            // Update the state with the new message and clear the input field
            setMessages(prevMessages => [...prevMessages, message]);
            setInputValue('');
    
            // Process the message
            initiateRun();
        } catch (error) {
            console.error('Error sending message:', error);
            clearTimeout(timeoutId); // Clear the timeout in case of error
    
            // Handle the case where the backend responds with a 403 error for insufficient credits
            if (error.response && error.response.status === 403) {
                alert(error.response.data.message);
            } else {
                alert('An error occurred while sending the message. Please try again.');
            }
        }
    };
    

    

    
    
   
    const handleSend = async () => {
        let combinedContent = '';
        let hasContent = false;
    
        // Append chat input
        if (inputValue.trim() !== '') {
            combinedContent += inputValue;
            hasContent = true;
        }
    
     // Append content from CodeMirror editor
if (showCodeEditor && editor) {
    const codeContent = editor.state.doc.toString();
    if (codeContent.trim() !== '') {
        if (combinedContent !== '') combinedContent += '\n';
        combinedContent += `\`\`\`${languageLabel}\n${codeContent}\n\`\`\``;
        hasContent = true;
    }
}

        // Append content from MathQuill if it's visible
        if (showMathInput && mathInput.trim() !== '') {
            if (combinedContent !== '') combinedContent += '\n'; // Add newline if there's already content
            combinedContent += `\`${mathInput}\``; // Append MathQuill input wrapped in backticks
            hasContent = true;
        }
    
        // Check if there's any content to send
    if (!hasContent) return;

    // Determine if it's the first message from user
    const isInitialUserMessage = messages.length === 1 && messages[0].role === 'assistant';

    // Send the combined message
    const userMessage = { role: 'user', content: combinedContent };
    await sendMessage(userMessage, isInitialUserMessage);
    
        // Clear inputs and editor
        setInputValue('');
        setMathInput('');
        if (showCodeEditor && editor) {
            editor.dispatch({
                changes: { from: 0, to: editor.state.doc.length, insert: '' } // Clear editor content
            });
        }
    
        // Reset the MathQuill field's content if visible
        if (showMathInput && mathFieldRef.current) {
            const MQ = window.MathQuill.getInterface(2);
            const mathField = MQ.MathField(mathFieldRef.current);
            mathField.latex(''); // Reset the MathQuill input to empty
        }
    };
    




    
    // Function to handle the starter button click
    const handleStarterClick = async (starterContent) => {
        if (!threadId || !starterContent) return;
        const userMessage = { role: 'user', content: starterContent };
        // Pass true to indicate this might be the first message from user
        await sendMessage(userMessage, true);
    };

// Function to initiate the run and handle timeouts
const initiateRun = async () => {
    setIsTyping(true);
    let isTimeout = false;

    // Set a timeout for the response
    const timeoutId = setTimeout(() => {
        isTimeout = true;
        setIsTyping(false);
        alert('The AI is taking longer than expected to respond. This delay may be due to a variety of factors including high demand on the AI service, performance issues, network delays, or your internet connection. Please check your connection and try resubmitting your last message. If the issue persists, refreshing the page may help. We apologize for the inconvenience and appreciate your patience.');
    }, 20000); // 20 seconds timeout

    try {
        const runResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/threads/${threadId}/runs`, {
            assistantId: apiAssistantId
        }, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        let runId = runResponse.data.id;
        let runStatus = runResponse.data.status;
        let processedStepIds = new Set();

        while ((runStatus === 'queued' || runStatus === 'in_progress') && !isTimeout) {
            await new Promise(resolve => setTimeout(resolve, 1000));

            const updatedRunResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/threads/${threadId}/runs/${runId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            runStatus = updatedRunResponse.data.status;

            const runStepsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/threads/${threadId}/runs/${runId}/steps`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });

            for (const step of runStepsResponse.data.data) {
                if (processedStepIds.has(step.id) || step.status !== 'completed') continue;

                if (step.type === 'message_creation' && step.step_details.message_creation) {
                    const messageId = step.step_details.message_creation.message_id;
                    const messageResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/threads/${threadId}/messages/${messageId}`, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    });

                    let imagePromise = null;
                    for (const c of messageResponse.data.content) {
                        if (c.type === 'image_file' && c.image_file) {
                            imagePromise = fetchAndDisplayImage(c.image_file.file_id, token);
                        }
                    }

                    if (imagePromise) await imagePromise;

                    for (const c of messageResponse.data.content) {
                        if (c.type === 'text' && c.text) {
                            setMessages(prev => [...prev, { role: 'assistant', content: processMessageContent(c.text.value), id: messageId }]);
                        }
                    }
                }

                processedStepIds.add(step.id);
            }

             // Check if it's time to clear the timeout
             if (!isTimeout) {
                clearTimeout(timeoutId);
            }
        }
    } catch (error) {
        console.error('Error during run initiation:', error);
        if (!isTimeout) {
            clearTimeout(timeoutId);
        }
        // Handle other errors, such as network issues
    }

    if (!isTimeout) {
        setIsTyping(false);
    }
};



  // Function to process the message content
const processMessageContent = (messageContent) => {
    if (typeof messageContent !== 'string') {
        return [{ type: 'text', content: String(messageContent) }];
    }

    const segments = messageContent.split(/(```.*?```|\[jsx\].*?\[\/jsx\])/s).map(seg => {
        if (seg.startsWith("```") && seg.endsWith("```")) {
            return { type: 'code', content: seg };
        } else if (seg.startsWith("[jsx]") && seg.endsWith("[/jsx]")) {
            return { type: 'jsxGraph', content: seg };
        } else {
            return { type: 'text', content: processMarkdown(seg) };
        }
    });

    return segments;
};

// Function to convert Markdown to HTML
const processMarkdown = (text) => {
    return text
        .replace(/\n/g, '<br />')                           // Convert newlines to <br />
        .replace(/#### (.*?)(<br \/>|<br>|$)/gm, '<u>$1</u>$2')  // Convert #### to underline
        .replace(/### (.*?)(<br \/>|<br>|$)/gm, '<strong><u>$1</u></strong>$2')  // Convert ### to bold and underline
        .replace(/## (.*?)(<br \/>|<br>|$)/gm, '<strong><u>$1</u></strong>$2')   // Convert ## to bold and underline
        .replace(/# (.*?)(<br \/>|<br>|$)/gm, '<strong><u>$1</u></strong>$2')    // Convert # to bold and underline
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')   // Convert **text** to <strong>text</strong>
        .replace(/\*(.*?)\*/g, '<em>$1</em>');              // Convert *text* to <em>text</em>
};

    const concatenateTextSegments = (messages) => {
        let allText = "";
    
        messages.forEach(message => {
            const segments = message.role === 'assistant' ? message.content : processMessageContent(message.content);
    
            segments.forEach(segment => {
                if (segment.type === 'text') {
                    // Assuming 'segment.content' is a string
                    allText += segment.content.replace(/<br \/>/g, ' ') + ' '; // Replace <br /> with space
                }
            });
        });
    
        return allText;
    };
    
    
    
    
    
    const convertQuillSyntaxToCodeBlock = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const preElements = doc.querySelectorAll('pre.ql-syntax');
    
        preElements.forEach(pre => {
            let lines = pre.textContent.split('\n').filter(line => line.trim() !== '');
            let language = '';
    
            // Check if the first line is a language identifier
            if (lines[0].match(/^(javascript|python|html|css|php)$/i)) {
                language = lines[0].trim();
                lines.shift(); // Remove the first line (language identifier)
            }
    
            // Format the Markdown code block correctly
            const markdownCode = `\`\`\`${language}\n${lines.join('\n')}\n\`\`\``;
            pre.outerHTML = markdownCode;
        });
    
        return doc.body.innerHTML;
    };
    
    
    
      
    
      useEffect(() => {
        if (assistant) {
            // Convert Quill syntax to Markdown-like code blocks
            const convertedContent = convertQuillSyntaxToCodeBlock(assistant.chat_instructions);
    
            // Process the converted content to handle code blocks
            const initialMessageContent = processMessageContent(convertedContent);
    
            setMessages([{
                role: 'assistant',
                content: initialMessageContent
            }]);
        }
    }, [assistant]);
    
    



  

    // Function to format message content with LaTeX
    const formatMessageWithLatex = (content) => {
        if (!content) {
            return ''; // Return an empty string if content is null or undefined
        }
    
        // Existing formatting code
        let formattedContent = content.replace(/`([^`]+)`/g, (_, latex) => `\\(${latex}\\)`);
        formattedContent = formattedContent.replace(/\$(.*?)\$/g, (_, latex) => `\\(${latex}\\)`);
        formattedContent = formattedContent.replace(/\\\[([^\\]+)\\\]/g, (_, latex) => `\\[${latex}\\]`);
        formattedContent = formattedContent.replace(/\\{([^\\]+)\\}/g, (_, latex) => `\\{${latex}\\}`);
    
        // Sanitize the final HTML content
       // Sanitize the final HTML content
const sanitizedContent = DOMPurify.sanitize(formattedContent, {
    ADD_TAGS: ['iframe'], // Allow iframe tags
    ADD_ATTR: ['frameborder', 'allowfullscreen', 'src', 'class'] // Allow specific attributes for iframe
  });
  return sanitizedContent;
  
    };
    

// Existing useEffect for triggering MathJax rendering
useEffect(() => {
    if (window.MathJax) {
        window.MathJax.typesetPromise();
    }
}, [messages, assistant]); 

// New useEffect for triggering MathJax rendering for MathQuill input
useEffect(() => {
    if (window.MathJax && mathToggleRef.current) {
      console.log("MathJax rendering for button");
      window.MathJax.typesetPromise([mathToggleRef.current]);
    } else {
      console.log("MathJax or mathToggleRef not available");
    }
  }, [showMathInput]);


  // Existing useEffect for CodeMirror editor
  useEffect(() => {
    // If an editor instance already exists, destroy it before creating a new one
    if (editor) {
      editor.destroy();
    }

    const newEditor = new EditorView({
      state: EditorState.create({
        doc: '//your code here', // Initial content of the editor
        extensions: [
          oneDark,
          languageMode, // Use the selected language mode
          lineNumbers(),
          keymap.of(defaultKeymap)
        ],
      }),
      parent: editorDiv.current,
    });

    setEditor(newEditor);

    // Cleanup function to destroy the editor instance when the component unmounts
    return () => {
      if (newEditor) {
        newEditor.destroy();
      }
    };
  }, [languageMode]); // Re-run the effect when languageMode changes
  
   



  const handleLanguageChange = (event) => {
    const mode = event.target.value;
    setLanguageLabel(mode); // Set the language label
    switch (mode) {
        case 'html':
            setLanguageMode(html);
            break;
        case 'css':
            setLanguageMode(css);
            break;
        case 'python':
            setLanguageMode(python);
            break;
        case 'php':
            setLanguageMode(php);
            break;
        case 'javascript':
        default:
            setLanguageMode(javascript);
            break;
    }
};



 // Function to dynamically adjust the height of the textarea
const handleTextareaChange = (e) => {
    const maxHeight = 200; // Maximum height in pixels

    // Reset the height to shrink to content size
    e.target.style.height = 'auto';

    // Set the height to the scroll height or the maximum height, whichever is smaller
    e.target.style.height = `${Math.min(e.target.scrollHeight, maxHeight)}px`;

    // Update the inputValue state
    setInputValue(e.target.value);
};


const handleNameChange = (e) => {
    setStudentName(e.target.value);
    setIsNameEntered(e.target.value.trim().length > 0);
  };



  return (
    <div className={`chat-wrapper ${isInIframe ? 'iframe-scaling' : ''}`}>
        {assistant && (
            <div className="assistant-info">
                <h2 className={`assistant-name1 ${isInIframe ? 'iframe-assistant-name' : ''}`}>
                    {assistant.assistant_name}
                </h2>
            </div>
        )}

        {assistant && assistant.picture && (
            <div className="persona-image-container">
                <img
                    src={assistant.picture}
                    alt={`${assistant.assistant_name}'s avatar`}
                    className={`persona-image ${isInIframe ? 'iframe-persona-image' : ''}`}
                />
            </div>
        )}

        {/* Student Name Input */}
        {assistant && assistant.Include_Student_Name_Input && (
            <div className={`student-name-explanation ${isInIframe ? 'iframe-student-name-explanation' : ''}`}>
                <p className={`student-name-text ${isInIframe ? 'iframe-student-name-text' : ''}`}>
                    Please enter your name before starting the conversation with the assistant.
                </p>
                <input
                    type="text"
                    id="studentName"
                    placeholder="Enter your name"
                    onChange={handleNameChange}
                    className={`student-name-input ${isInIframe ? 'iframe-student-name-input' : ''}`}
                />
            </div>
        )}

        <br/>

        <div className="chat-container" ref={chatContainerRef}>
            <>
                {messages.map((message, index) => {
                    const segments = message.role === 'assistant' ? message.content : processMessageContent(message.content);
                    const isExpanded = expandedMessages.has(index);

                    const shouldShowTopButtons = segments.reduce((acc, segment) => {
                        if (segment.type === 'text') {
                            return acc + segment.content.length;
                        }
                        return acc;
                    }, 0) > CHARACTER_LIMIT;

                    return (
                        <div key={index} className={`chat-message ${message.role}`} style={message.role === 'user' ? { backgroundColor: color } : { position: 'relative' }}>
                            
                            {/* Conditional Rendering of Buttons at the Top */}
                            {shouldShowTopButtons && message.role === 'assistant' && (
                                <div className="button-container">
                                    <div className="button-container-left">
                                        <button
                                            onClick={() => audioStatus[message.id]?.isPlaying || audioStatus[message.id]?.isPaused ? stopAudio(message.id) : playAudio(message.id, message.content)}
                                            disabled={(assistant?.Include_Student_Name_Input && !isNameEntered) || isTyping}
                                            className={`audio-btn ${audioStatus[message.id]?.isPlaying || audioStatus[message.id]?.isPaused ? 'playing' : ''}`}
                                        >
                                            <i className={`fas ${audioStatus[message.id]?.isPlaying || audioStatus[message.id]?.isPaused ? 'fa-times' : 'fa-headphones'} audio-icon`}></i>
                                        </button>
                                        {audioStatus[message.id]?.isPlaying && (
                                            <button
                                                onClick={() => pauseAudio(message.id)}
                                                disabled={(assistant?.Include_Student_Name_Input && !isNameEntered) || isTyping}
                                                className="audio-btn"
                                            >
                                                <i className="fas fa-pause audio-icon"></i>
                                            </button>
                                        )}
                                        {audioStatus[message.id]?.isPaused && (
                                            <button
                                                onClick={() => playAudio(message.id)}
                                                disabled={(assistant?.Include_Student_Name_Input && !isNameEntered) || isTyping}
                                                className="audio-btn"
                                            >
                                                <i className="fas fa-play audio-icon"></i>
                                            </button>
                                        )}
                                        {!isAudioLoading[message.id] && (
                                            <span className="audio-status">AI generated voice</span>
                                        )}
                                        {isAudioLoading[message.id] && <span className="audio-status">Creating Audio ⏳</span>}
                                    </div>
                                    <button 
                                        onClick={() => toggleExpand(index)} 
                                        className="expand-collapse-btn"
                                    >
                                        <i className={`fas ${isExpanded ? "fa-compress-alt" : "fa-expand-alt"} audio-icon`}></i>
                                    </button>
                                </div>
                            )}
                    
                            {/* Message Content */}
                            {segments.map((segment, segIndex) => {
                                switch (segment.type) {
                                    case 'code':
                                        const [firstLine, ...codeLines] = segment.content.substring(3, segment.content.length - 3).split('\n');
                                        const language = firstLine.trim();
                                        const code = codeLines.join('\n').trim();
                                        return <CodeDisplay key={segIndex} code={code} language={language} />;
                                    case 'jsxGraph':
                                        const jsxCode = segment.content.substring(5, segment.content.length - 6).trim();
                                        const boardId = `jsxgraph-${index}-${segIndex}`;
                                        return <JSXGraphComponent key={segIndex} jsxCode={jsxCode} boardId={boardId} />;
                                    case 'text':
                                        const formattedText = formatMessageWithLatex(segment.content);
                                        return (
                                            <span 
                                                key={segIndex} 
                                                dangerouslySetInnerHTML={{ __html: formattedText }} 
                                            />
                                        );
                                    default:
                                        console.log("Unexpected segment type:", segment);
                                        return <span key={segIndex}>Unsupported content format</span>;
                                }
                            })}
                    
                            {/* Expand/Collapse Icon at the Bottom Right */}
                            {message.role === 'assistant' && (
                                <button 
                                    onClick={() => toggleExpand(index)} 
                                    className="expand-collapse-btn-bottom"
                                >
                                    <i className={`fas ${isExpanded ? "fa-compress-alt" : "fa-expand-alt"} audio-icon`}></i>
                                </button>
                            )}
                    
                            {/* Audio Playback Button */}
                            {message.role === 'assistant' && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <button
                                        onClick={() => audioStatus[message.id]?.isPlaying || audioStatus[message.id]?.isPaused ? stopAudio(message.id) : playAudio(message.id, message.content)}
                                        disabled={(assistant?.Include_Student_Name_Input && !isNameEntered) || isTyping}
                                        className={`audio-btn ${audioStatus[message.id]?.isPlaying || audioStatus[message.id]?.isPaused ? 'playing' : ''}`}
                                    >
                                        <i className={`fas ${audioStatus[message.id]?.isPlaying || audioStatus[message.id]?.isPaused ? 'fa-times' : 'fa-headphones'} audio-icon`}></i>
                                    </button>
                                    {audioStatus[message.id]?.isPlaying && (
                                        <button
                                            onClick={() => pauseAudio(message.id)}
                                            disabled={(assistant?.Include_Student_Name_Input && !isNameEntered) || isTyping}
                                            className="audio-btn"
                                        >
                                            <i className="fas fa-pause audio-icon"></i>
                                        </button>
                                    )}
                                    {audioStatus[message.id]?.isPaused && (
                                        <button
                                            onClick={() => playAudio(message.id)}
                                            disabled={(assistant?.Include_Student_Name_Input && !isNameEntered) || isTyping}
                                            className="audio-btn"
                                        >
                                            <i className="fas fa-play audio-icon"></i>
                                        </button>
                                    )}
                                    {!isAudioLoading[message.id] && (
                                        <span className="audio-status">AI generated voice</span>
                                    )}
                                    {isAudioLoading[message.id] && <span className="audio-status">Creating Audio ⏳</span>}
                                </div>
                            )}
                        </div>
                    );
                    
                    
                    
                    

                    
                    
                })}

                {isTyping && (
                    <div className="chat-message assistant">
                        <span style={{ fontWeight: 'bold' }}>{assistant?.assistant_name || 'Assistant'} is typing
                            <div className="typing-dots">
                                <span></span><span></span><span></span>
                            </div>
                        </span>
                    </div>
                )}
            </>
        </div>

        {/* Render PopOut for any expanded message */}
        {[...expandedMessages].map(expandedIndex => (
            <PopOut
                key={expandedIndex}
                message={messages[expandedIndex]}
                onClose={() => toggleExpand(expandedIndex)}
            />
        ))}

        {/* CodeMirror editor container with toggleable visibility */}
        <div 
            className={showCodeEditor ? "codemirror-container" : "hidden"} 
            style={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                marginBottom: '12px',
                width: '90%',
                marginLeft: 'auto',
                marginRight: 'auto'
            }}
        >
            <select 
                onChange={handleLanguageChange} 
                style={{ 
                    marginBottom: '10px', 
                    width: 'auto',
                    maxWidth: '200px',
                    padding: '4px 4px',
                    borderRadius: '4px',
                    border: '1px solid #ced4da',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)',
                    cursor: 'pointer',
                    outline: 'none',
                    fontSize: '0.9rem',
                    color: '#495057',
                    appearance: 'none', 
                    textAlign: 'center', 
                    textAlignLast: 'center',
                }}
            >
                <option value="html">HTML</option>
                <option value="javascript">JavaScript</option>
                <option value="css">CSS</option>
                <option value="python">Python</option>
                <option value="php">php</option>
            </select>
            <div ref={editorDiv} style={{ height: '100%', width: '100%' }}></div>
        </div>

        {/* MathQuill container with toggleable visibility */}
        <div className={showMathInput ? "mathquill-container" : "hidden"} style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '12px' }}>
            <span 
                ref={mathFieldRef} 
                style={{ display: 'inline-block', minWidth: '200px', flexGrow: 1 }}
                className="mathquill-input"
            ></span>
            <button onClick={handleMathSubmit} className="send-button" style={{ backgroundColor: color, padding: '5px', fontSize: '10px' }}>
                <i className="bi bi-arrow-down"></i>
            </button>
        </div>

        {/* Chat input with toggle buttons for MathQuill and CodeMirror */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px', width: '100%' }}>
            <textarea
                value={inputValue}
                onChange={handleTextareaChange}
                className="chat-input"
                placeholder={assistant?.Include_Student_Name_Input && !isNameEntered 
                    ? "Please add your name above to start chatting" 
                    : "Type your message..."}
                onKeyPress={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSend();
                    }
                }}
                disabled={(assistant?.Include_Student_Name_Input && !isNameEntered) || isTyping}
                style={{ 
                    flexGrow: 1, 
                    resize: 'none', 
                    overflowY: 'auto',
                    height: '42px'
                }}
            />
            <button 
                onClick={toggleMathInput}
                disabled={assistant?.Include_Student_Name_Input && !isNameEntered}
                className="btn btn-outline-secondary"
                style={{ 
                    width: '40px', 
                    height: '40px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    marginTop: '-9px'
                }}
            >
                {showMathInput ? "hide" : <i className="fas fa-square-root-alt"></i>}
            </button>
            <button 
                onClick={toggleCodeEditor}
                disabled={assistant?.Include_Student_Name_Input && !isNameEntered}
                className="btn btn-outline-secondary"
                style={{ 
                    width: '40px', 
                    height: '40px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    marginTop: '-9px'
                }}
            >
                {showCodeEditor ? "hide" : <i className="fas fa-code"></i>}
            </button>
        </div>

        <button onClick={handleSend} className="send-button" style={{backgroundColor: color}} disabled={(assistant?.Include_Student_Name_Input && !isNameEntered) || isTyping}>
            Send
        </button>

        {/* Conversation Starters */}
        <div className="mt-2">
            {assistant && assistant.starter_1 && (
                <button 
                    className="btn btn-outline-secondary mb-2 w-100" 
                    disabled={assistant?.Include_Student_Name_Input && !isNameEntered}
                    onClick={() => handleStarterClick(assistant.starter_1)}
                    dangerouslySetInnerHTML={{ __html: formatMessageWithLatex(assistant.starter_1) }}
                />
            )}
            {assistant && assistant.starter_2 && (
                <button 
                    className="btn btn-outline-secondary mb-2 w-100" 
                    disabled={assistant?.Include_Student_Name_Input && !isNameEntered}
                    onClick={() => handleStarterClick(assistant.starter_2)}
                    dangerouslySetInnerHTML={{ __html: formatMessageWithLatex(assistant.starter_2) }}
                />
            )}
            {assistant && assistant.starter_3 && (
                <button 
                    className="btn btn-outline-secondary mb-2 w-100" 
                    disabled={assistant?.Include_Student_Name_Input && !isNameEntered}
                    onClick={() => handleStarterClick(assistant.starter_3)}
                    dangerouslySetInnerHTML={{ __html: formatMessageWithLatex(assistant.starter_3) }}
                />
            )}
            {assistant && assistant.starter_4 && (
                <button 
                    className="btn btn-outline-secondary mb-2 w-100" 
                    disabled={assistant?.Include_Student_Name_Input && !isNameEntered}
                    onClick={() => handleStarterClick(assistant.starter_4)}
                    dangerouslySetInnerHTML={{ __html: formatMessageWithLatex(assistant.starter_4) }}
                />
            )}
            {assistant && assistant.starter_5 && (
                <button 
                    className="btn btn-outline-secondary mb-2 w-100" 
                    disabled={assistant?.Include_Student_Name_Input && !isNameEntered}
                    onClick={() => handleStarterClick(assistant.starter_5)}
                    dangerouslySetInnerHTML={{ __html: formatMessageWithLatex(assistant.starter_5) }}
                />
            )}
        </div>

        <button className="btn btn-outline-secondary mt-2" disabled={assistant?.Include_Student_Name_Input && !isNameEntered} onClick={refreshChat}>
            Refresh Chat
        </button>
    </div>
);




    
};

export default ChatComponent;
