import React from 'react';

const AccessRestricted = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h2>Access Restricted</h2>
            <p style={{ maxWidth: '600px', margin: 'auto', lineHeight: '1.6' }}>
                We're sorry, but the AI Assistant you're trying to access is restricted to a specific domain.
                This is to ensure that the assistant is used within the designated environment, such as a particular educational or organizational domain.
            </p>
            <p style={{ maxWidth: '600px', margin: 'auto', lineHeight: '1.6', marginTop: '20px' }}>
                If you believe you're receiving this message in error, please contact the administrator or the person who provided you with this link.
            </p>
        </div>
    );
};

export default AccessRestricted;
