// ChatHistoryComponent.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import ChatThreadPopup from './ChatThreadPopup';
import './ChatHistoryComponent.css';

function ChatHistoryComponent() {
    const [searchParams] = useSearchParams();
    const assistantId = searchParams.get('assistantId'); // Get assistantId from query params

    const [chatHistory, setChatHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedThreadId, setSelectedThreadId] = useState(null); // State to manage selected threadId
    const [selectedStudentName, setSelectedStudentName] = useState(null);

    useEffect(() => {
        const fetchChatHistory = async () => {
            try {
                if (assistantId) { // Fetch chat history only if assistantId is available
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/threads/history/${assistantId}`, {
                        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                    });
                    setChatHistory(response.data);
                }
            } catch (err) {
                setError('Failed to fetch chat history.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchChatHistory();
    }, [assistantId]); // Rerun the effect when assistantId changes

    const openThreadPopup = (threadId, studentName) => {
        console.log("Opening thread popup for threadId:", threadId);
        setSelectedThreadId(threadId);
        setSelectedStudentName(studentName); // Set the student's name
    };

// Function to close chat thread popup
const closeThreadPopup = () => {
    setSelectedThreadId(null);
};





    // Function to group chat history by date
    const groupByDate = (history) => {
        return history.reduce((group, chat) => {
            const date = new Date(chat.createdAt).toLocaleDateString();
            group[date] = group[date] || [];
            group[date].push(chat);
            return group;
        }, {});
    };

    const groupedChatHistory = groupByDate(chatHistory);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="chat-history-container">
            {Object.keys(groupedChatHistory).length > 0 ? (
                Object.keys(groupedChatHistory).map(date => (
                    <div key={date} className="date-group">
                        <h3 className="date-header">{date}</h3>
                        <div className="gallery">
                            {groupedChatHistory[date].map(chat => (
                            <div key={chat.thread_id} className="chat-history-item" onClick={() => openThreadPopup(chat.thread_id, chat.student)}>
                                <div className="student-name">{chat.student || 'Unknown Student'}</div>
                                <div className="date-time">{new Date(chat.createdAt).toLocaleTimeString()}</div>
                            </div>
                            ))}
                        </div>
                    </div>
                ))
            ) : (
                <p>No chat history available.</p>
            )}

            {selectedThreadId && (
            <ChatThreadPopup 
                threadId={selectedThreadId} 
                studentName={selectedStudentName} 
                closePopup={closeThreadPopup} 
            />
        )}
        </div>
    );
}

export default ChatHistoryComponent;