import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Dashboard.css';
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill'; 
import ImageResize from 'quill-image-resize';
import GlobalSettings from './GlobalSettings';
import exemplarAssistants from './ExemplarAssistants';

Quill.register('modules/imageResize', ImageResize);


function CreatePersona() {
  const navigate = useNavigate();


  const [assistantName, setAssistantName] = useState('');
  const [description, setDescription] = useState('');
  const [model, setModel] = useState('');
  const [personality, setPersonality] = useState('');
  const [initialPrompt, setInitialPrompt] = useState('');
  const [pictureUrl, setPictureUrl] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [chatInstructions, setChatInstructions] = useState('');
  //const [aboveChatText, setAboveChatText] = useState('');
  const [descriptionLocal, setDescriptionLocal] = useState('');

  //const { openaiLowerModel, openaiHigherModel } = GlobalSettings.openaiModels;
  //const { displayLowerModel, displayHigherModel } = GlobalSettings.displayModels;
  const [includeStudentNameInput, setIncludeStudentNameInput] = useState(false);
  const [selectedExemplar, setSelectedExemplar] = useState('');
  const [restrictedDomain, setRestrictedDomain] = useState('');


  // States for showing explanations
  //const [showNameExplanation, setShowNameExplanation] = useState(false);
  //const [showDescriptionExplanation, setShowDescriptionExplanation] = useState(false);
  const [showPersonalityExplanation, setShowPersonalityExplanation] = useState(false);
  const [showInitialPromptExplanation, setShowInitialPromptExplanation] = useState(false);
  const [showImageUrlExplanation, setShowImageUrlExplanation] = useState(false);
  const [showStartersExplanation, setShowStartersExplanation] = useState(false);
  const [showChatInstructionsExplanation, setShowChatInstructionsExplanation] = useState(false);
  //const [showAboveChatTextExplanation, setShowAboveChatTextsExplanation] = useState(false);
  const [showDescriptionLocalExplanation, setShowDescriptionLocalExplanation] = useState(false);
  const [showModelExplanation, setShowModelExplanation] = useState(false);
  const [showStudentNameInputExplanation, setShowStudentNameInputExplanation] = useState(false);
  const [showExemplarExplanation, setShowExemplarExplanation] = useState(false);
  const [showRestrictedDomainExplanation, setShowRestrictedDomainExplanation] = useState(false);



  // States for conversation starters
  const [starter1, setStarter1] = useState('');
  const [starter2, setStarter2] = useState('');
  const [starter3, setStarter3] = useState('');
  const [starter4, setStarter4] = useState('');
  const [starter5, setStarter5] = useState('');

  const [category, setCategory] = useState('');
const [subCategory, setSubCategory] = useState('');


useEffect(() => {
  const validateToken = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      await axios.get(`${backendUrl}/validate-token`, { headers: { Authorization: `Bearer ${token}` } });
      // If the token is valid, proceed with no action.
    } catch (err) {
      // If the token is invalid or there's another error, redirect to login.
      navigate('/login');
    }
  };
  
  validateToken();
}, [navigate]);


  // Handle change in exemplar selection
  const handleExemplarChange = (e) => {
    const exemplarName = e.target.value;
    setSelectedExemplar(exemplarName);
    const exemplar = exemplarAssistants.find(assistant => assistant.name === exemplarName);

    if (exemplar) {
      // Populate the form fields with the exemplar's details
      setAssistantName(exemplar.name);
      setDescription(exemplar.description);
      setModel(exemplar.model);
      setPersonality(exemplar.personality);
      setChatInstructions(exemplar.chatInstructions);
      setInitialPrompt(exemplar.initialPrompt);
      setPictureUrl(exemplar.pictureUrl);
      setStarter1(exemplar.starter1);
      setStarter2(exemplar.starter2);
      setStarter3(exemplar.starter3);
      setStarter4(exemplar.starter4);
      setStarter5(exemplar.starter5);
      setDescriptionLocal(exemplar.descriptionLocal); // Set descriptionLocal
      setIncludeStudentNameInput(exemplar.includeStudentNameInput); // Assuming this field exists in your exemplar object
      // Add any additional fields that are part of your assistant form
  }
  
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
    // Check if any required field is empty and alert the user
    if (!assistantName) {
      alert('Please enter an Assistant Name.');
      return;
    }
  
    if (!chatInstructions) {
      alert("Please enter the Assistant's Welcome & Resources.");
      return;
    }
  
    if (!personality) {
      alert("Please enter the Assistant's Personality & Background.");
      return;
    }
  
    if (!model) {
      alert('Please select a model for your assistant.');
      return;
    }
  
    try {
      const response = await axios.post(`${backendUrl}/assistants`, {
        assistantName,
        description,
        model,
        personality,
        chatInstructions,
        descriptionLocal,
        initialPrompt,
        pictureUrl,
        starter_1: starter1,
        starter_2: starter2,
        starter_3: starter3,
        starter_4: starter4,
        starter_5: starter5,
        Include_Student_Name_Input: includeStudentNameInput,
        restrictedDomain,
        category,
        subCategory
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (response.data) {
        window.location.href = '/dashboard';
      }
    } catch (error) {
      console.error('Error creating assistant:', error.response ? error.response.data : error.message);
      alert('Failed to create assistant. Please try again.');
    }
  };
  



  /*const handleImprovePersona = async () => {
    setIsProcessing(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/improve-persona`, {
        systemMessage: personality
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      setPersonality(response.data.improvedMessage);
    } catch (error) {
      console.error('Error improving persona:', error.response ? error.response.data : error.message);
      alert('Failed to improve persona. Please try again.');
    }
    setIsProcessing(false);
  };*/

  // Function to improve conversation starter
const handleImproveStarter = async (starterIndex) => {
  setIsProcessing(true);
  try {
    const starter = [starter1, starter2, starter3, starter4, starter5][starterIndex];
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/improve-starter`, {
      starterText: starter
    }, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    const improvedStarter = response.data.improvedStarter;
    switch(starterIndex) {
      case 0: setStarter1(improvedStarter); break;
      case 1: setStarter2(improvedStarter); break;
      case 2: setStarter3(improvedStarter); break;
      case 3: setStarter4(improvedStarter); break;
      case 4: setStarter5(improvedStarter); break;
      default: break;
    }
  } catch (error) {
    console.error('Error improving starter:', error.response ? error.response.data : error.message);
    alert('Failed to improve starter. Please try again.');
  }
  setIsProcessing(false);
};


const handleGenerateDescription = async () => {
  setIsProcessing(true);
  try {
    const payload = {
      assistantName: assistantName, // State variable for assistant's name
      welcomeResources: chatInstructions, // State variable for chat instructions, used as welcome resources
      model: model, // State variable for model
      personalityBackground: personality, // State variable for assistant's personality and background
      instructionsToAssistant: initialPrompt // State variable for initial prompt (instructions to assistant)
    };

    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/generate-description`, payload, {
      headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
    });

    setDescriptionLocal(response.data.generatedDescription);
  } catch (error) {
    console.error('Error generating description:', error);
    alert('Failed to generate description. Please try again.');
  }
  setIsProcessing(false);
};





  const handleUrlChange = (e) => {
    const url = e.target.value;
    setPictureUrl(url);
    setIsUrlValid(isValidUrl(url));
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <div className="container mt-5 dashboard-container">
      <div className="dashboard-header">
        <h2>Create Your AI Assistant</h2>
        <p>Complete the information below to create your custom AI teacher assistant.</p>
      </div>
      <form onSubmit={handleSubmit}>

     

{/* Exemplar Assistant Dropdown */}
<div className="exemplar-assistants-section mt-4 p-3 border rounded" style={{ backgroundColor: '#f8f9fa', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
  <h5 className="text-center mb-3">Assistants Gallery</h5>
  <div className="d-flex flex-column align-items-center">
    <div className="d-flex mb-2 align-items-center">
      <label className="form-label me-2">Select a premade assistant or start from scratch</label>
      <button 
        type="button" 
        className="btn btn-sm" 
        style={{ background: 'transparent' }}
        onClick={() => setShowExemplarExplanation(!showExemplarExplanation)}
      >
        <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
      </button>
    </div>

    {showExemplarExplanation && (
  <div className="description-info-text text-left mb-3">
    <p>Welcome to the Assistants Gallery! This curated collection features a variety of Exemplar Assistants, each designed with specific educational themes and functionalities. These exemplars serve as a starting point for creating your unique AI assistant, offering inspiration and a foundation for customization.</p>
    <p>By selecting an Exemplar Assistant, you can explore different approaches to AI-assisted teaching and learning. Each exemplar is equipped with a distinct set of characteristics, conversation starters, and instructional content, reflecting various pedagogical strategies and subject matter expertise.</p>
    <p>Use these exemplars as a springboard to:
      <ul>
        <li>Gain insights into different ways AI can be integrated into teaching.</li>
        <li>Understand how specific features and settings influence student interactions.</li>
        <li>Experiment with and adapt the content to suit your teaching style and students' needs.</li>
      </ul>
    </p>
    <p>Feel free to explore, modify, and enhance these templates to create an AI assistant that aligns with your educational goals and resonates with your students.</p>
  </div>
)}


    <select
      className="form-select persona-dropdown"
      value={selectedExemplar}
      onChange={handleExemplarChange}
    >
      <option value="">Select an Exemplar</option>
      {exemplarAssistants.map(assistant => (
        <option key={assistant.name} value={assistant.name}>{assistant.name}</option>
      ))}
    </select>
  </div>
</div>




<br/>
<div className="note-required-fields">
  <p>Fields marked with an <span className="required-symbol">*</span> are required.</p>
</div>


<br/>

        {/* Assistant Name */}
        <div className="form-group mb-4">
        <label><h5>Assistant Name<span className="required-symbol">*</span></h5></label>


          <input
            type="text"
            className="form-control placeholder-style"
            placeholder="Enter a name for your AI assistant..."
            value={assistantName}
            onChange={(e) => setAssistantName(e.target.value)}
            required
            disabled={isProcessing}
          />
        </div>
       
  {/* Assistant's Welcome & Resources */}
  <div className="form-group mb-4">
  <label><h5>Assistant's Welcome & Resources<span className="required-symbol">*</span></h5></label>
  <button 
    type="button" 
    className="btn btn-sm ml-2" 
    style={{ background: 'transparent' }}
    onClick={() => setShowChatInstructionsExplanation(!showChatInstructionsExplanation)}
  >
    <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
  </button>

  {showChatInstructionsExplanation && (
  <div className="description-info-text">
    <p>This section allows you to craft an initial message that students will see when they start interacting with the assistant. Although it appears as part of the conversation, the AI does not recognize or "know" this content as part of its conversational context. If you want the AI to be aware of certain information or context, integrate it into the 'Assistant's Personality & Background' and/or 'Instructions to Assistant' sections.</p>
    <p>Consider the following when crafting this message:</p>
    <ul>
      <li><strong>Set the Tone:</strong> Your message should welcome students and set a friendly and inviting tone for the interaction.</li>
      <li><strong>Include Resources:</strong> Use the rich text editor to add educational content such as explanations, tips, or external links to resources. This can be articles, videos, or other learning aids relevant to the subject matter.</li>
      <li><strong>Visibility:</strong> Remember that students can expand the chat to view the content more clearly, so feel free to include detailed information or resources.</li>
      <li><strong>Encourage Interaction:</strong> Your message can include prompts or questions to encourage students to start engaging with the assistant.</li>
    </ul>
   
  </div>
)}

<ReactQuill
  theme="snow"
  value={chatInstructions}
  onChange={setChatInstructions}
  placeholder="Hello, I'm your sarcastic AI assistant. I'm here to help you with factoring!"
  style={{ height: '300px', paddingBottom: '40px' }}
  readOnly={isProcessing} // Disables editing when isProcessing is true
  modules={{
    imageResize: {},
    toolbar: [
      [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
      ['bold', 'italic', 'underline', 'strike'],       // toggled buttons
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      ['clean'],
      ['link', 'image', 'video'],                      // link and image, video
      ['code-block']
    ]
  }}
/>

</div>



<br/>

       {/* Model Dropdown */}
<div className="form-group mb-4">
  <label><h5>Model<span className="required-symbol">*</span></h5></label>
  <button 
    type="button" 
    className="btn btn-sm ml-2" 
    style={{ background: 'transparent' }}
    onClick={() => setShowModelExplanation(!showModelExplanation)}
  >
    <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
  </button>
  {showModelExplanation && (
    <div className="description-info-text">
      <p>Select the AI model for your assistant. Each model has different capabilities:</p>
      <ul>
        <li><strong>gpt-4-1106-preview:</strong> Advanced model offering the latest features and capabilities.</li>
        <li><strong>gpt-4:</strong> Standard GPT-4 model, balancing performance and resource usage.</li>
        <li><strong>gpt-3.5-turbo:</strong> Efficient model for faster responses, suitable for most general purposes.</li>
      </ul>
      <p>Choose the model that best fits your assistant's needs and the complexity of tasks it will handle.</p>
    </div>
  )}
  <select 
    className="form-control" 
    value={model} 
    onChange={(e) => setModel(e.target.value)}
    disabled={isProcessing}
  >
    <option value="">-- Select Model --</option>
    <option value={GlobalSettings.openaiModels.openaiLowerModel}>{GlobalSettings.displayModels.displayLowerModel}</option>
    <option value={GlobalSettings.openaiModels.openaiHigherModel}>{GlobalSettings.displayModels.displayHigherModel}</option>
  </select>
</div>


        {/* Personality */}
        <div className="form-group mb-4">
          <label><h5>Assistant's Personality & Background<span className="required-symbol">*</span></h5></label>
          <button 
            type="button" 
            className="btn btn-sm ml-2" 
            style={{ background: 'transparent' }}
            onClick={() => setShowPersonalityExplanation(!showPersonalityExplanation)}
          >
            <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
          </button>

          {showPersonalityExplanation && (
  <div className="description-info-text">
  <p>This optional section allows you to fine-tune the assistant's engagement with your students by providing initial guidance. Think of it as a behind-the-scenes message that sets the stage for the conversation. This preemptive prompt can sharpen the assistant's focus on the intended subject or task right from the get-go, enhancing its interactions with students.</p>
  
  <p><strong>Suggested opening for your instructions:</strong></p>
  <blockquote>
    "As a teaching assistant, you're tasked with guiding my students through the intricacies of [subject/topic]."
  </blockquote>

  <p>While this preparatory command steers the AI towards its intended function, it remains invisible to students, ensuring a seamless start to their interaction.</p>

  <p><strong>Elements to incorporate in your initial prompt:</strong></p>
  <ul>
    <li><strong>Role Clarification:</strong> Define the assistant's purpose and how it relates to the educational content or activity.</li>
    <li><strong>Interaction Objectives:</strong> Outline the main objectives for student engagement, such as aiding in problem-solving, elucidating concepts, or fostering curiosity.</li>
    <li><strong>Behavioral Expectations:</strong> Detail the preferred response style and tone, aligning it with the learning environment's ethos.</li>
  </ul>

  <p>Integrating this initial direction is optional but can significantly impact the assistant's effectiveness by providing a clearer context for its interactions. Please note, incorporating an initial prompt will be considered an additional message in terms of credit usage, similar to other messages exchanged during the chat.</p>
</div>

)}

          <textarea
            className="form-control placeholder-style"
            placeholder="You are a sarcastic AI assistant who is here to help students with [subject/topic]..."
            value={personality}
            onChange={(e) => setPersonality(e.target.value)}
            required
            rows={6}
            disabled={isProcessing}
          />
        {/*  <button 
            type="button" 
            className="btn btn-outline-secondary mt-2" 
            onClick={handleImprovePersona} 
            disabled={isProcessing}
          >
            Improve My Assistant
          </button> */}
        </div>

        {/* Knowledge File 
        <div className="form-group mb-4">
          <label className="font-weight-bold">Assistant Knowledge File (Optional)</label>
          <input
            type="file"
            className="form-control"
            onChange={handleFileChange}
          />
        </div> */}

      

        {/* Instructions to Assistant */}
        <div className="form-group mb-4">
          <label><h5>Instructions to Assistant (Optional)</h5></label>
          <button 
            type="button" 
            className="btn btn-sm ml-2" 
            style={{ background: 'transparent' }}
            onClick={() => setShowInitialPromptExplanation(!showInitialPromptExplanation)}
          >
            <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
          </button>
          {showInitialPromptExplanation && (
  <div className="description-info-text">
  <p>This optional section allows you to fine-tune the assistant's engagement with your students by providing initial guidance. Think of it as a behind-the-scenes message that sets the stage for the conversation. This preemptive prompt can sharpen the assistant's focus on the intended subject or task right from the get-go, enhancing its interactions with students.</p>
  
  <p><strong>Suggested opening for your instructions:</strong></p>
  <blockquote>
    "As a teaching assistant, you're tasked with guiding my students through the intricacies of [subject/topic]."
  </blockquote>

  <p>While this preparatory command steers the AI towards its intended function, it remains invisible to students, ensuring a seamless start to their interaction.</p>

  <p><strong>Elements to incorporate in your initial prompt:</strong></p>
  <ul>
    <li><strong>Role Clarification:</strong> Define the assistant's purpose and how it relates to the educational content or activity.</li>
    <li><strong>Interaction Objectives:</strong> Outline the main objectives for student engagement, such as aiding in problem-solving, elucidating concepts, or fostering curiosity.</li>
    <li><strong>Behavioral Expectations:</strong> Detail the preferred response style and tone, aligning it with the learning environment's ethos.</li>
  </ul>

  <p>Integrating this initial direction is optional but can significantly impact the assistant's effectiveness by providing a clearer context for its interactions. Please note, incorporating an initial prompt will be considered an additional message in terms of credit usage, similar to other messages exchanged during the chat.</p>
</div>

)}
      <textarea
  className="form-control placeholder-style"
  placeholder="As a teaching assistant, you will now engage with my students to help them understand [subject/topic]."
  value={initialPrompt}
  onChange={(e) => setInitialPrompt(e.target.value)}
  rows={6}
  disabled={isProcessing}
/>

        </div>



<br/>
<br/>

{/* Conversation Starters Section */}
<div className="form-group mb-4">
  <label><h5>Conversation Starters</h5></label>
  <button 
    type="button" 
    className="btn btn-sm ml-2" 
    style={{ background: 'transparent' }}
    onClick={() => setShowStartersExplanation(!showStartersExplanation)}
  >
    <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
  </button>
  {showStartersExplanation && (
  <div className="description-info-text">
    <p>This section is for creating quick-access prompts that students can use to initiate specific topics or questions in their chat with the assistant. These starters are designed to guide student interactions, making it easier for them to engage with the AI on relevant topics.</p>

    <p>Consider these tips for creating effective conversation starters:</p>
    <ul>
      <li><strong>Common Questions:</strong> Include prompts for frequently asked questions or common topics in your subject area.</li>
      <li><strong>Guided Learning:</strong> Use starters to steer students towards particular learning objectives or key concepts.</li>
      <li><strong>Variety:</strong> Offer a range of starters covering different aspects of the subject to cater to diverse student needs and interests.</li>
    </ul>
    
    <p><strong>Pro Tip:</strong> Leverage conversation starters for tailored AI responses. By setting a starter to a specific question or phrase, and then referencing it in the 'Assistant's Personality & Background' or 'Instructions to Assistant,' you can program the AI to take a particular action or provide a specific type of response when a student selects that starter. This allows for more controlled and predictable interactions based on the selected prompt.</p>
    
    <p>Well-crafted conversation starters not only make the chat more interactive but also enhance the educational value of the AI assistant by focusing discussions on key learning areas.</p>
    <p>Note: For math to render properly in this section it needs to be writted in LaTeX form and enclosed in backticks. You can click the 'Format Math' button to have AI convert your math into the correct format</p>
  </div>
)}
  
  <div className="d-flex align-items-start mb-2">
    <textarea className="form-control me-2 placeholder-style" placeholder="How do I factor `x^2-2x-15`" value={starter1} onChange={(e) => setStarter1(e.target.value)} rows={2} disabled={isProcessing}/>
    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => handleImproveStarter(0)} disabled={isProcessing}>
    Format Math/Code
    </button>
  </div>

  <div className="d-flex align-items-start mb-2">
    <textarea className="form-control me-2 placeholder-style" placeholder="Why can't I factor out an x?" value={starter2} onChange={(e) => setStarter2(e.target.value)} rows={2} disabled={isProcessing} />
    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => handleImproveStarter(1)} disabled={isProcessing}>
      Format Math/Code
    </button>
  </div>

  <div className="d-flex align-items-start mb-2">
    <textarea className="form-control me-2 placeholder-style" placeholder="Help me figure out numbers that multiply to -18 and add to 3" value={starter3} onChange={(e) => setStarter3(e.target.value)} rows={2} disabled={isProcessing}/>
    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => handleImproveStarter(2)} disabled={isProcessing}>
    Format Math/Code
    </button>
  </div>

  <div className="d-flex align-items-start mb-2">
    <textarea className="form-control me-2 placeholder-style" placeholder="Conversation Starter 4" value={starter4} onChange={(e) => setStarter4(e.target.value)} rows={2} disabled={isProcessing}/>
    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => handleImproveStarter(3)} disabled={isProcessing}>
    Format Math/Code
    </button>
  </div>

  <div className="d-flex align-items-start mb-2">
    <textarea className="form-control me-2 placeholder-style" placeholder="Conversation Starter 5" value={starter5} onChange={(e) => setStarter5(e.target.value)} rows={2} disabled={isProcessing}/>
    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => handleImproveStarter(4)} disabled={isProcessing}>
    Format Math/Code
    </button>
  </div>
</div>
<br/>
<br/>

        {/* Image URL */}
        <div className="form-group mb-4">
          <label><h5>Image URL (Optional)</h5></label>
          <button 
            type="button" 
            className="btn btn-sm ml-2" 
            style={{ background: 'transparent' }}
            onClick={() => setShowImageUrlExplanation(!showImageUrlExplanation)}
          >
            <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
          </button>
          {showImageUrlExplanation && (
  <div className="description-info-text">
    <p>This section allows you to add a visual representation for your AI assistant. The image you choose will appear at the top of the chat interface, providing a personalized touch and making the chat experience more engaging for students.</p>
    
    <p>To add an image:</p>
    <ul>
      <li><strong>Find an Image:</strong> Choose an image that best represents the character or theme of your AI assistant. This could be a relevant photograph, illustration, or any visual that complements the assistant's personality.</li>
      <li><strong>Get the Image URL:</strong> Right-click on the image and select 'Copy image link.' If the link doesn't work directly, try clicking on the image to view it in full size before copying the link.</li>
      <li><strong>Test the Link:</strong> Ensure the image URL works by pasting it into your browser's address bar and checking if the image loads correctly.</li>
      <li><strong>Respect Copyright:</strong> Use only images that are free for use or for which you have the necessary permissions.</li>
    </ul>

    <p>Adding an image makes the assistant more visually appealing and relatable, contributing to a richer interactive experience for students.</p>
  </div>
)}

          <input
            type="text"
            className="form-control placeholder-style"
            placeholder="Provide an image URL..."
            value={pictureUrl}
            onChange={handleUrlChange}
            disabled={isProcessing}
          />
          {!isUrlValid && <p className="text-danger">Invalid URL</p>}
        </div>

{/* Restricted Domain */}
<div className="form-group mb-4">
    <label><h5>Restricted Domain</h5></label>
    <button 
        type="button" 
        className="btn btn-sm ml-2" 
        style={{ background: 'transparent' }}
        onClick={() => setShowRestrictedDomainExplanation(!showRestrictedDomainExplanation)}
    >
        <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
    </button>
    {showRestrictedDomainExplanation && (
        <div className="description-info-text">
            <p>This field allows you to restrict where your AI assistant can be accessed. Enter the domain where the assistant will be embedded or used.</p>
            <p>For example, if you only want the assistant to be accessible from your school's learning management system, you would enter the LMS's domain here.</p>
            <p><strong>Note:</strong> The assistant will only respond to requests originating from this domain. Ensure you enter the correct domain to avoid accessibility issues.</p>
        </div>
    )}
    <input
        type="text"
        className="form-control placeholder-style"
        placeholder="Enter the domain (e.g., www.myschool.edu)"
        value={restrictedDomain}
        onChange={(e) => setRestrictedDomain(e.target.value)}
    />
</div>

{/* Include Student Name Input Section */}
<div className="text-center">
    <div>
        <h5 className="d-inline">Include Student Name Input</h5>
        <button 
            type="button" 
            className="btn btn-sm" 
            style={{ background: 'transparent' }}
            onClick={() => setShowStudentNameInputExplanation(!showStudentNameInputExplanation)}
        >
            <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
        </button>
    </div>
    <div className="form-check form-switch d-flex justify-content-center mt-2">
        <input
            type="checkbox"
            className="form-check-input"
            id="studentNameInputSwitch"
            checked={includeStudentNameInput}
            onChange={(e) => setIncludeStudentNameInput(e.target.checked)}
            role="switch"
        />
    </div>
    {showStudentNameInputExplanation && (
        <div className="description-info-text mt-3">
            <p>This toggle allows you to include a student name input field in the chat interface. When enabled, students will be required to enter their name before they can interact with the assistant.</p>
        </div>
    )}
</div>
<br/>
<br/>

 {/* Description of Assistant */}
        <div className="form-group mb-4">
  <label><h5>Description of Assistant</h5></label>
  <button 
    type="button" 
    className="btn btn-sm ml-2" 
    style={{ background: 'transparent' }}
    onClick={() => setShowDescriptionLocalExplanation(!showDescriptionLocalExplanation)}
  >
    <i className="bi bi-info-circle" style={{ color: '#007bff' }}></i>
  </button>

  {showDescriptionLocalExplanation && (
  <div className="description-info-text">
    <p>This description serves a dual purpose:</p>
    <ul>
      <li>
        <strong>Personal Reminder:</strong> It acts as a reminder for you, the creator, about the specific functions, characteristics, and intended use of your AI assistant. This is particularly helpful if you manage multiple assistants or need to recall the distinct features of this assistant at a later time.
      </li>
      <li>
        <strong>Information for Other Teachers (if Public):</strong> If your AI assistant is shared publicly or with other teachers, this description provides a concise overview of what the assistant does. It informs other educators about the assistant's capabilities, usage scenarios, and any unique attributes that make it useful for educational purposes.
      </li>
    </ul>
    <p>In crafting your description, consider including:</p>
    <ul>
      <li>The main objectives and teaching goals of the assistant.</li>
      <li>Specific subjects, topics, or areas of expertise the assistant covers.</li>
      <li>Unique interaction styles or pedagogical approaches used by the assistant.</li>
      <li>Any prerequisites or intended audience (e.g., grade levels, knowledge level).</li>
      <li>Brief examples or use cases to illustrate how the assistant can be utilized in an educational setting.</li>
    </ul>
    <p>Remember, a clear and detailed description will enhance the usability of your assistant for both yourself and other educators, fostering a more collaborative and informed teaching environment.</p>
  </div>
)}

<div className="d-flex align-items-start mb-2">
  <textarea
    className="form-control me-2 placeholder-style"
    placeholder="Describe how your AI assistant works..."
    value={descriptionLocal}
    onChange={(e) => setDescriptionLocal(e.target.value)}
    rows={5} // Adjust the number of rows as needed
    disabled={isProcessing}
  />
  <button 
    type="button" 
    className="btn btn-outline-secondary btn-sm custom-button-size" 
    onClick={handleGenerateDescription}
    disabled={isProcessing}
  >
    Generate Description Using AI
  </button>
</div>



</div>

{/* Category and Sub_Category */}
<div className="form-group mb-4">
    <label><h5>Category and Sub-Category</h5></label>
    <div className="d-flex justify-content-between">
        {/* Category */}
        <div className="flex-fill mr-2">
            <input
                type="text"
                className="form-control placeholder-style"
                placeholder="Enter category (e.g., Math)"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
            />
        </div>

        {/* Sub-Category */}
        <div className="flex-fill ml-2">
            <input
                type="text"
                className="form-control placeholder-style"
                placeholder="Enter sub-category (e.g., Algebra)"
                value={subCategory}
                onChange={(e) => setSubCategory(e.target.value)}
            />
        </div>
    </div>
</div>


<button type="submit" className="btn btn-outline-primary" disabled={isProcessing}>
  <i className="bi bi-plus-square-fill" style={{ marginRight: '8px' }}></i>
  Create Assistant
</button>

      </form>
    </div>
  );
}

export default CreatePersona;