import React from 'react';
import { PayPalButtons } from "@paypal/react-paypal-js";

function PayPalPayment({ amount, currency, teacherId }) {
    const createOrder = () => {
        // Replace /my-server/create-paypal-order with your actual backend endpoint
        return fetch("/my-server/create-paypal-order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                amount: amount,
                currency: currency,
                teacherId: teacherId,
            }),
        })
        .then((response) => response.json())
        .then((order) => order.id); // Ensure your backend sends back the order ID in this format
    };

    const onApprove = (data) => {
        
        return fetch("/my-server/capture-paypal-order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                orderID: data.orderID, // Make sure to send the correct order ID key as expected by your backend
            }),
        })
        .then((response) => response.json())
        .then((details) => {
            console.log('Payment was successful!', details);
            // Here, you can handle the successful payment, e.g., updating UI or state
        })
        .catch((err) => {
            console.error('Payment capture failed!', err);
            // Handle payment capture errors here
        });
    };

    const onPaymentError = (err) => {
        console.error('Payment failed!', err);
        // Handle general payment errors here
    };

    return (
        <PayPalButtons
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onPaymentError}
        />
    );
}

export default PayPalPayment;

