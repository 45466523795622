import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function TextToSpeechComponent() {
    const [inputText, setInputText] = useState('');
    const [selectedVoice, setSelectedVoice] = useState('alloy');
    const [loadingState, setLoadingState] = useState(''); // '' (none), 'audio', 'text'
    const [isMp3Created, setIsMp3Created] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState('');
    const [characterLimitExceeded, setCharacterLimitExceeded] = useState(false);
    const [isEditingEnabled, setIsEditingEnabled] = useState(true);
    const audioRef = useRef(new Audio());

    const navigate = useNavigate();

    useEffect(() => {
        const validateToken = async () => {
          const token = localStorage.getItem('token');
          if (!token) {
            navigate('/login');
            return;
          }
          try {
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            await axios.get(`${backendUrl}/validate-token`, { headers: { Authorization: `Bearer ${token}` } });
            // If the token is valid, proceed with no action.
          } catch (err) {
            // If the token is invalid or there's another error, redirect to login.
            navigate('/login');
          }
        };
        
        validateToken();
      }, [navigate]);

    const voices = ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'];

    const createOrUpdateMp3 = async () => {
        if (!isEditingEnabled) setIsEditingEnabled(true); // Enable editing if it was disabled
        setLoadingState('audio');
        const token = localStorage.getItem('token'); // Retrieve the token
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/speak`, {
                text: inputText,
                voice: selectedVoice
            }, {
                headers: { 'Authorization': `Bearer ${token}` }, // Include the token in the request header
                responseType: 'blob'
            });
    
            if (response.status === 200) {
                const audioUrl = URL.createObjectURL(response.data);
                audioRef.current.src = audioUrl;
                setDownloadUrl(audioUrl);
                setIsMp3Created(true);
                setIsEditingEnabled(false); // Disable editing after creating MP3
            }
        } catch (error) {
            setLoadingState('');
            if (error.response && error.response.status === 400) {
                // Handle insufficient credits
                alert('You have insufficient credits for this action. Please purchase more credits to proceed.');
            } else if (error.response && error.response.status === 500) {
                // Handle server error during TTS conversion
                alert('There was an error processing your request. Sometimes this is an issue at Open AI due to heavy use on their servers. You have not been charged for these credits.');
            } else {
                // Handle other errors
                alert('An unexpected error occurred. Please try again.');
            }
        } finally {
            setLoadingState('');
        }
    };
    
    
    

    const togglePlayback = () => {
        if (audioRef.current.paused) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    };

    const handleStop = () => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
    };

    const handleRewordText = async () => {
        setLoadingState('text');
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/reword-text`, { text: inputText });
            setInputText(response.data.rewordedText);
        } catch (error) {
            console.error('Error rewording text:', error);
        } finally {
            setLoadingState('');
        }
    };

 

    const handleTextChange = (e) => {
        const newText = e.target.value;
        if (newText.length <= 4500) {
            setInputText(newText);
            setCharacterLimitExceeded(false);
        } else {
            setCharacterLimitExceeded(true);
        }
    };

    const handlePaste = (e) => {
        const pastedText = e.clipboardData.getData('text');
        const newText = inputText + pastedText;
        if (newText.length > 4500) {
            e.preventDefault();
            setCharacterLimitExceeded(true);
        }
    };

    const isProcessing = loadingState !== '';
    const processingMessage = loadingState === 'audio' ? 'Loading audio...' : 'Formatting text to make some characters more readable...';


    return (
        <div className="container mt-4 p-3 border rounded" style={{ backgroundColor: '#f8f9fa', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <div className="mb-4">
                <h5>Text-to-Speech Converter</h5>
                <p>This tool allows you to convert your text into spoken word with a 4500 character limit. Choose a voice, paste your text, and press "Create MP3" to generate the audio file.</p>
            </div>
            <div className="form-group mb-4">
    <label htmlFor="voiceSelection" className="form-label"><strong>Select Voice</strong></label>
    <select 
        id="voiceSelection" 
        className="form-select" 
        value={selectedVoice} 
        onChange={(e) => setSelectedVoice(e.target.value)}
        disabled={!isEditingEnabled || loadingState !== ''}
    >
        {voices.map(voice => (
            <option key={voice} value={voice}>{voice}</option>
        ))}
    </select>
    </div>
            <div className="form-group mb-3">
                <label htmlFor="textInput" className="form-label"><strong>Input Text</strong></label>
                <textarea
                    id="textInput"
                    className="form-control"
                    value={inputText}
                    onChange={handleTextChange}
                    onPaste={handlePaste}
                    placeholder="Type or paste text here..."
                    rows="20"
                    maxLength="4500"
                    disabled={!isEditingEnabled || loadingState !== ''}
                ></textarea>
            </div>
            {characterLimitExceeded && (
                <p className="text-danger">The text exceeds the 4500 character limit. Please shorten it.</p>
            )}
            {isProcessing && <p className="mt-2">{processingMessage}</p>}
            <div className="d-flex align-items-center justify-content-between mb-3">
    {/* Show "Edit" button only if an MP3 has been created and editing is not enabled */}
    {isMp3Created && !isEditingEnabled && (
        <button className="btn btn-outline-secondary" onClick={() => setIsEditingEnabled(true)}>
            Edit
        </button>
    )}

    {/* Show "Create MP3" or "Update MP3" button based on the state */}
    {isEditingEnabled || !isMp3Created ? (
        <button className={`btn ${isMp3Created ? 'btn-warning' : 'btn-primary'}`} onClick={createOrUpdateMp3} disabled={isProcessing}>
            {isMp3Created ? 'Update MP3' : 'Create MP3'}
        </button>
    ) : null}

    {/* Conditional rendering for Download, Play/Pause, and Stop buttons based on editing state */}
    {!isEditingEnabled && downloadUrl && (
        <>
            <a href={downloadUrl} download="text-to-speech.mp3" className="btn btn-success mx-2">Download MP3</a>
            <button className="btn btn-info" onClick={togglePlayback} disabled={isProcessing}>Play/Pause</button>
            <button className="btn btn-danger mx-2" onClick={handleStop} disabled={isProcessing}>Stop</button>
        </>
    )}

    {/* Show "Format Symbols" button only when editing is enabled */}
    {isEditingEnabled && (
        <button className="btn btn-outline-secondary" onClick={handleRewordText} disabled={isProcessing || inputText.length === 0}>
            Format Symbols
        </button>
    )}
</div>

        </div>
    );
    
}

export default TextToSpeechComponent;
